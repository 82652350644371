import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getPageRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { Page } from "../typescript/pages";
import styles from "../styles/Index.module.css";
import errorStyles from "../styles/Error.module.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Error() {
  const lpTs = useLivePreviewCtx();
  const history = useNavigate();
  const [getEntries, setEntries] = useState({} as Page);
  const [error, setError] = useState(false);
  const { i18n } = useTranslation();
  async function fetchData() {
    try {
      const result = await getPageRes("/404");
      if (!result) {
        setError(true);
      } else {
        setEntries(result);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }

  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;
  const darkBackground = getEntries.dark_background
    ? styles.darkBackground
    : "";
  useEffect(() => {
    fetchData();
    error && history("/404");
  }, [lpTs, error, i18n.language]);

  return Object.keys(getEntries).length ? (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="robots" content="no index, no follow" />
      </Helmet>
      <div className={`${darkBackground} ${errorStyles.errorContainer}`}>
        <RenderComponents
          pageComponents={getEntries?.page_components}
          contentTypeUid="page"
          entryUid={getEntries?.uid}
          locale={getEntries?.locale}
          taxonomies={getEntries?.taxonomies}
        />
      </div>
    </>
  ) : (
    <Skeleton count={5} height={400} />
  );
}
