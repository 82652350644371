import React from "react";
import { HeroTitle as HeroTitlePros } from "../typescript/components";
import styles from "../styles/HeroTitle.module.css";
import { useLocation } from "react-router";

export default function HeroTitle({
  hero_title,
}: {
  hero_title: HeroTitlePros;
}) {
  const alignTextClass =
    hero_title?.align_text === "Left"
      ? styles.textAlignLeft
      : styles.textAlignCenter;

  const bottomBorder = hero_title.bottom_border ? styles.bottomBorder : "";

  const location = useLocation();
  const path = location.pathname;

  // Split the path into segments, skipping the language segment (usually the first one)
  const segments = path
    .split("/")
    .filter((segment) => segment)
    .slice(1);

  const breadcrumbs = [
    { name: "Home", path: "/" },
    ...segments.map((segment, index) => {
      const segmentPath = `/${segments.slice(0, index + 1).join("/")}`;
      return {
        name:
          segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, " "),
        path: segmentPath,
      };
    }),
  ];

  if (hero_title && breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].name = hero_title.title;
  }

  return (
    <div
      className={`${styles.containerHeroTitle} ${alignTextClass} ${bottomBorder}`}
    >
      {breadcrumbs && hero_title.show_breadcrumb && (
        <div className={styles.breadcrumb}>
          {breadcrumbs.map((breadcrumb, index) => (
            <span key={index}>
              <a
                href={breadcrumb.path}
                className={
                  index === breadcrumbs.length - 1
                    ? styles.currentBreadcrumb
                    : styles.breadcrumbLink
                }
              >
                {breadcrumb.name}
              </a>
              {index < breadcrumbs.length - 1 && (
                <span className={styles.breadcrumbLink}> /&nbsp;</span>
              )}
            </span>
          ))}
        </div>
      )}
      <div className={styles.eyebrow}>{hero_title?.eyebrow}</div>
      {hero_title?.header_tag_type === "H3" ? (
        <h3 className={styles.title}>{hero_title?.title}</h3>
      ) : (
        <h1 className={styles.title}>{hero_title?.title}</h1>
      )}
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: hero_title.content }}
      ></div>
    </div>
  );
}
