import * as React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { currentBrandPrimary500 } from '../../sdk/utils';
interface StyledTableCellProps {
  isCheckbox?: boolean; 
}

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isCheckbox',
})<StyledTableCellProps>(({ theme, isCheckbox }) => ({
  fontFamily: "Roboto Condensed",
  [`&.${tableCellClasses.head}`]: {
    color: currentBrandPrimary500,
    backgroundColor:  "var(--white)",
    fontSize: 12,
    fontWeight: 700,
    height: 35,
    padding: '0 10px',
    borderLeft: '1px solid var(--charcoal-200)',
    borderTop: '1px solid var(--charcoal-200)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '0 10px',
    height: 35,
    borderLeft: '1px solid var(--charcoal-200)',
  
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: 12,
    height: 35,
    padding: '0 10px',
    color:  "var(--white)",
    backgroundColor: currentBrandPrimary500,
    borderLeft: '1px solid var(--charcoal-200)',

  },
  '&&:nth-of-type(1)': {
    padding: isCheckbox ? 0 : 'auto',
    width: isCheckbox ? 25 : 'auto',
  },
  '&:nth-of-type(2)': {
    color: currentBrandPrimary500,
  },
  '&:last-child': {
    borderRight: '1px solid var(--charcoal-200)',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(34, 115, 186, 0.05)',
  },
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid var(--charcoal-200)',
  },
}));



