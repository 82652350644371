import React, { useEffect, useState } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "../styles/BlogGrid.module.css";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import Button from "./button";
import BlogCard from "./blog-card";
import i18n from "../sdk/i18n";
export default function BlogGrid({
  resource_grid }: {
  resource_grid: ResourceGridProp;
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
    }) {

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.language);
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

  return (
    <div className={styles.blogGridContainer}>
      {
        (resource_grid.eyebrow || resource_grid.title) && 
        <div className={styles.blogGridHeading}>
          <h4 className={styles.blogGridEyebrow}>{resource_grid.eyebrow}</h4>
          <h3 className={styles.blogGridTitle}>{resource_grid.title}</h3>
        </div>
      }
      <div className={styles.blogGrid}>
      {
        resource_grid.featured_resources && resource_grid.featured_resources.length > 0 &&
        <section className={styles.cards}>
            {resource_grid.featured_resources.slice(0, 3).map((item, index) => (
              <div key={index} className={styles.slide}>
                    <BlogCard key={`BlogCard-${currentLanguage}`}
                  blog_post={item}
                  component_type={resource_grid.component_type}
                />
              </div>
            ))}
        </section>
      }
        {resource_grid.cta &&
          resource_grid.cta.title &&
          resource_grid.cta.url && (
            <Button
              text={resource_grid.cta.title}
              showIcon
              propWidth="250px"
              propBackgroundColor="var(--primary-500)"
              propHoverBackgroundColor="transparent"
              propTextColor="var(--white)"
              link={resource_grid.cta.url}
            />
          )}
      </div>
    </div>
  );
}
