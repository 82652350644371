import React, { useEffect, useState } from "react";
import styles from "../styles/ServicesTab.module.css";
import {
  ServicesTab as ServicesTabProp,
  ServiceTabDetail,
} from "../typescript/components";
import WistiaVideo from "./wistia-video";

export default function ServicesTab({
  services_tab,
}: {
  services_tab: ServicesTabProp;
}) {
  const { tabs, title } = services_tab;
  const [tab, setTab] = useState<ServiceTabDetail | null>(tabs?.[0]);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 1200);
  const isEqual = (
    tab1: ServiceTabDetail | null,
    tab2: ServiceTabDetail | null
  ) => JSON.stringify(tab1) === JSON.stringify(tab2);
  const isVideo = (filename: string) => {
    return /\.(mp4|webm|ogg)$/i.test(filename);
  };

  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };
  const handleSetTab = (newTab: ServiceTabDetail) => {
    if (!isDesktopView && isEqual(newTab, tab)) {
      setTab(null);
      return;
    }

    setTab(newTab);
  };

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth > 1200;
      setIsDesktopView(isDesktop);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isDesktopView && !tab) {
      setTab(tabs?.[0]);
    }
  }, [isDesktopView]);

  if (!tab && isDesktopView) return <></>;
  return (
    <div className={styles.servicesTabs}>
      <h3 className={styles.title}>{title}</h3>
      <section className={styles.container}>
        <div className={styles.tabsVertical}>
          {tabs.map((item, index) => (
            <div className={styles.tabWrapper} key={index}>
              <div
                className={styles.verticalTab}
                onClick={() => handleSetTab(item)}
              >
                <div className={`${styles.tab} ${isEqual(tab, item) && styles.active}`}>{item.tab_title}</div>
                <img
                  className={styles.expandIcon}
                  loading="lazy"
                  alt="Expand"
                  src={isEqual(tab, item) ? "/minus.svg" : "/plus.svg"}
                />
              </div>
              {isEqual(tab, item) && (
                <div className={styles.mobilePreview}>
                  {tab?.media?.url ? (
                    isVideo(tab.media.filename) ? (
                      <div className={styles.videoWrapper}>
                        <video
                          autoPlay
                          muted
                          loop
                          className={styles.backgroundMedia}
                        >
                          <source src={tab.media.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : (
                      <img
                        src={tab.media.url ? tab.media.url + "?width=700&auto=webp&fit=crop,smart" : "/large-blank-card.png"}
                        alt={tab.media.title}
                        loading="lazy"
                        className={styles.img}
                      />
                    )
                  ) : (
                    tab?.wistia && (
                      <WistiaVideo embedUrl={tab?.wistia} responsive={true} />
                    )
                  )}
                  <div className={styles.detail}>
                    <div className={styles.detailLabel}>{item.title}</div>
                    <div className={styles.description}>{item.description}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.preview}>
          {tab?.media?.url ? (
            isVideo(tab.media.filename) ? (
              <div className={styles.videoWrapper}>
                <video autoPlay muted loop className={styles.backgroundMedia}>
                  <source src={tab.media.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <img src={tab.media.url ? tab.media.url + "?width=700&auto=webp&fit=crop,smart" : "/large-blank-card.png"}
                alt={tab.media.title}
                loading="lazy"
                className={styles.img}
              />
            )
          ) : (
            tab?.wistia && (
              <div className={styles.videoWrapper}>
                <WistiaVideo embedUrl={tab?.wistia} responsive={true} />
              </div>
            )
          )}
          <div className={styles.copy}>
            {tab?.title && (
              <div className={styles.benefitLabeledIn}>{tab.title}</div>
            )}
            {tab?.description && (
              <div className={styles.briefDescriptionOf}>{tab.description}</div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
