import React, { useState } from "react";
import styles from "../styles/Filters.module.css";
import { Filter as FilterProp } from "../typescript/components";
import Button from "./button";
import { translate } from "../helper/translate";
import i18n from "../sdk/i18n";
import SidebarModal from "./sidebar-modal";

export default function Filter({
  filter,
  onApplyFilters,
}: {
  filter: FilterProp;
  onApplyFilters: (selectedFilters: any) => void;
}) {
  const [isExpanded, setIsExpanded] = useState<string | null>(null);

  // State to track selected filters
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedCertifications, setSelectedCertifications] = useState<
    string[]
  >([]);
  const [selectedMetals, setSelectedMetals] = useState<string[]>([]);

  // Toggle function to change the expanded state for a specific location
  const toggleExpand = (uid: string) => {
    setIsExpanded(isExpanded === uid ? null : uid);
  };

  // Handle changes to location checkboxes
  const handleLocationChange = (uid: string) => {
    setSelectedLocations((prevSelected) =>
      prevSelected.includes(uid)
        ? prevSelected.filter((id) => id !== uid)
        : [...prevSelected, uid]
    );
  };

  // Handle changes to certification checkboxes
  const handleCertificationChange = (uid: string) => {
    setSelectedCertifications((prevSelected) =>
      prevSelected.includes(uid)
        ? prevSelected.filter((id) => id !== uid)
        : [...prevSelected, uid]
    );
  };

  // Handle changes to metals checkboxes
  const handleMetalsChange = (uid: string) => {
    setSelectedMetals((prevSelected) =>
      prevSelected.includes(uid)
        ? prevSelected.filter((id) => id !== uid)
        : [...prevSelected, uid]
    );
  };

  // Function to gather selected filters and pass to parent
  const applyFilters = () => {
    const selectedFilters = {
      locations: selectedLocations,
      certifications: selectedCertifications,
      metals: selectedMetals,
    };
    onApplyFilters(selectedFilters); // Call the parent component's handler with selected filters
  };

  // Function to clear all filters and reset inputs
  const clearFilters = () => {
    // Clear all selected filters
    setSelectedLocations([]);
    setSelectedCertifications([]);
    setSelectedMetals([]);

    // Apply filters with empty selections
    onApplyFilters({ locations: [], certifications: [], metals: [] });
  };

  // Function to filter locations by depth and parent UID
  const getSubLocations = (parentUid: string) => {
    return (
      filter?.locations?.filter(
        (location) => location.parent_uid === parentUid
      ) || []
    );
  };

    return (
        <SidebarModal
            content={
                <div className={styles.aboutBanner}>
                    <div className={styles.root}>
                        <section className={styles.locationsOptions}>
                            <div className={styles.categoryOptions}>
                                <div className={styles.category}>
                                    <a className={styles.filters}>{translate("filters", i18n.language)}</a>
                                </div>
                                <div className={styles.clearAllFiltersButton}>
                                    <a className={styles.clearAllFilters} onClick={clearFilters}>
                                        {translate("clear_all_filters", i18n.language)}
                                    </a>
                                </div>
                            </div>
                            <div className={styles.filterOptions}>
                                <div className={styles.locationFilter}>
                                    {filter.locations && filter.locations.length > 0 && (
                                        <div className={styles.locationOptions}>
                                            <div className={styles.locationName}>
                                                <div className={styles.filterHeader}>{translate("locations", i18n.language)}</div>
                                            </div>
                                            <div className={styles.locationSelect}>
                                                <div className={styles.locationsSelectWrapper}>
                                                    {filter.locations
                                                        .filter((location) => location.depth === 1) // Ensure depth is treated as a string or adjust accordingly
                                                        .map((location) => (
                                                            <div key={location.uid}>
                                                                <label className={styles.locationSelectOptions}>
                                                                    <input type="checkbox" value={location.uid} />
                                                                    <span
                                                                        className={`${styles.selectBoxExpand} ${isExpanded === location.uid
                                                                                ? styles.minus
                                                                                : styles.plus
                                                                            }`}
                                                                        onClick={() => toggleExpand(location.uid)}
                                                                    ></span>
                                                                    <span className={styles.continent}>
                                                                        {location.name}
                                                                    </span>
                                                                </label>
                                                                {isExpanded === location.uid && (
                                                                    <div className={styles.subLocationWrapper}>
                                                                        {getSubLocations(location.uid).map(
                                                                            (subLocation) => (
                                                                                <label
                                                                                    key={subLocation.uid}
                                                                                    className={
                                                                                        styles.locationSelectOptionsIndent
                                                                                    }
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={subLocation.uid}
                                                                                        onChange={() =>
                                                                                            handleLocationChange(subLocation.uid)
                                                                                        }
                                                                                        checked={selectedLocations.includes(
                                                                                            subLocation.uid
                                                                                        )}
                                                                                    />
                                                                                    <span
                                                                                        className={styles.selectBoxCheck}
                                                                                    ></span>
                                                                                    <span className={styles.country}>
                                                                                        {subLocation.name}
                                                                                    </span>
                                                                                </label>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {filter.certifications &&
                                        filter.certifications.length > 0 && (
                                    <div className={styles.certificationSelect}>
                                        <div className={styles.certifications}>
                                            <div className={styles.filterHeader}>{translate("certifications", i18n.language)}</div>
                                        </div>
                                        {filter.certifications &&
                                            filter.certifications.length > 0 &&
                                            filter.certifications.map((certification) => (
                                                <label
                                                    key={certification.uid}
                                                    className={styles.locationSelectOptions}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={certification.uid}
                                                        onChange={() =>
                                                            handleCertificationChange(certification.uid)
                                                        }
                                                        checked={selectedCertifications.includes(
                                                            certification.uid
                                                        )}
                                                    />
                                                    <span className={styles.selectBoxCheck}></span>
                                                    <span className={styles.country}>
                                                        {certification.name}
                                                    </span>
                                                </label>
                                            ))}
                                            </div>
                                        )}
                                    {filter.metals &&
                                        filter.metals.length > 0 && (
                                            <div className={styles.metalsSelect}>
                                                <div className={styles.metals}>
                                                    <div className={styles.filterHeader}>{translate("metals", i18n.language)}</div>
                                                </div>
                                                {filter.metals &&
                                                    filter.metals.length > 0 &&
                                                    filter.metals.map((metal) => (
                                                        <label
                                                            key={metal.uid}
                                                            className={styles.locationSelectOptions}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                value={metal.uid}
                                                                onChange={() => handleMetalsChange(metal.uid)}
                                                                checked={selectedMetals.includes(metal.uid)}
                                                            />
                                                            <span className={styles.selectBoxCheck}></span>
                                                            <span className={styles.country}>{metal.name}</span>
                                                        </label>
                                                    ))}
                                            </div>)}
                                </div>
                            </div>
                        </section>
                        <Button
                            text="Apply Filters"
                            showIcon={false}
                            propWidth="15.625rem"
                            propHeight="2.625rem"
                            propBackgroundColor="var(--primary-500)"
                            propHoverBackgroundColor="transparent"
                            propTextColor="var(--white)"
                            onClick={applyFilters} // Call applyFilters when the button is clicked
                        />
                    </div>
                </div>
            } />
  );
}
