import React from "react";
import styles from "../styles/GenericSmallCard.module.css";
import { GenericSmallCard as GenericSmallCardProp } from "../typescript/components";

export default function GenericSmallCard({
  small_card,
}: {
  small_card: GenericSmallCardProp;
}) {
  return (
    <div className={styles.genericSmallCard}>
      <div className={styles.environmentalWrapper}>
        <img
          className={styles.environmentalIcon}
          loading="lazy"
          alt={small_card?.icon?.title}
          src={small_card?.icon?.url}
        />
      </div>
      <div className={styles.titleWrapper}>
        <a className={styles.title}>{small_card?.title}</a>
      </div>
      <div className={styles.description}>{small_card?.description}</div>
    </div>
  );
}
