import styled from "@emotion/styled";
import { Tab, Tabs } from "@mui/material";
import React from "react";

interface StyledTabProps {
    label: string;
    value?: number;
}
export const CustomizedTabs = styled(Tabs)({
    borderBottom: "none",
    "& .MuiTabs-indicator": {
        backgroundColor: "var(--brand-primary-500)",
        height: 4,
    },
});

export const CustomizedTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    fontSize: 16,
    fontFamily: 'var(--standard-font)',
    borderBottom: "var(--3xs, 2px) solid #8B8D97",
    color: "#bfbebe",
    fontWeight: 500,
    "&:hover": {
        color: "var(--charcoal-500)",
        opacity: 1,
    },
    "&.Mui-selected": {
        fontSize: 16,
        color: "var(--charcoal-500)",
        fontWeight: 700,
    },
}));