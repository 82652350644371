import React from "react";
import styles from "../styles/SimpleCard.module.css";
import { SimpleCard as SimpleCardProp } from "../typescript/components";
import Button from "./button";
import Skeleton from "react-loading-skeleton";

export default function SimpleCard({
  simple_card,
}: {
  simple_card: SimpleCardProp;
}) {
  const {
    title,
    description,
    simple_cards,
    title_icon,
    cta,
    title_alt,
    eyebrow,
    title_image,
    max_row_items = 6,
  } = simple_card;

  // default 6 items per row;
  const flexItemStyle = `1 1 calc(${100 / (max_row_items)}% - var(--3xl))` 

  return (
    <div className={styles.container}>
      {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
      {title_icon && title_image && (
        <div className={styles.titleIcon}>
          {title_image.url ? <img
            src={title_image.url}
            alt={title_image.description || "Logo image"}
            className={styles.logoImage}
          /> : <Skeleton width={100} />}
        </div>
      )}
      <div className={styles.content}>
        {(title || title_alt) && (
          <div
            className={styles.titleWrapper}
          >
            <h3 className={styles.title}>
              {title}
            </h3>
            <h3 className={styles.titleAlt}>
                {title_alt && title_alt}
              </h3>
          </div>
        )}
        {description && <p className={styles.description}>{description}</p>}
      </div>
      {simple_cards.length > 0 && (
        <div className={styles.itemContainer}>
                  {simple_cards.map((item, index) => (
                      <div className={styles.smallCard} key={index} style={{ flex: flexItemStyle }}>
                          {item.link && item.link.href ? (
                              <a href={item.link.href} className={styles.smallCard} target="_blank" rel="noopener noreferrer">
                                  {(item.icon && item.icon.url) ? (
                                      <img
                                          className={styles.icon}
                                          src={item.icon.url}
                                          alt={item.icon.title}
                                      />
                                  ) : (
                                      <Skeleton width={50} />
                                  )}
                                  <h5 className={styles.smallTitle}>{item.title}</h5>
                                  <p className={styles.smallDescription}>{item.description}</p>
                              </a>
                          ) : (
                              <>
                                  {(item.icon && item.icon.url) ? (
                                      <img
                                          className={styles.icon}
                                          src={item.icon.url}
                                          alt={item.icon.title}
                                      />
                                  ) : (
                                      <Skeleton width={50} />
                                  )}
                                  <h5 className={styles.smallTitle}>{item.title}</h5>
                                  <p className={styles.smallDescription}>{item.description}</p>
                              </>
                          )}
                      </div>
                  ))}
        </div>
      )}

      {cta?.title && cta.title.trim() !== "" && (
        <div className={styles.button}>
          <Button
            text={cta.title}
            showIcon
            propWidth="250px"
            propBackgroundColor={`var(--charcoal-500)`}
            propHoverBackgroundColor="transparent"
            propTextColor="var(--white)"
            link={cta.url || cta.href}
          /> 
        </div>
      )}
    </div>
  );
}
