import * as React from "react";
import Slider, { SliderProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const CustomSlider = styled(Slider)(({ theme }) => ({
    color: "var(--brand-primary-500)",
    height: 4,
    padding: 0,
    margin: 0,
    boxShadow: "none",
    "& .MuiSlider-thumb": {
        height: 18,
        width: 18,
        border: "1px solid var(--brand-primary-500)",
        backgroundColor: "var(--white)",
        boxShadow: "none",
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "none",
            "@media (hover: none)": {
                boxShadow: "none",
            },
        },
    },
    "& .MuiSlider-valueLabel": {
        color: 'var(--charcoal-500)',
        backgroundColor: 'transparent',
        top: 4,
        fontSize: 'var(--font-size-xs)'
    },

    "& .MuiSlider-mark": {
        width: 0.25,
        height: 14,
        backgroundColor: "var(--charcoal-200)",
    },
    "& .MuiSlider-markLabel": {
        display: "none",
    },
    "& .MuiSlider-track": {
        boxSizing: 'border-box',
        border: 'none',
        borderRadius: 8,
        "&:after": {
            content: '""',
            position: "absolute",
            display: "block",
            borderRadius: 8,
            width: "calc(100% + 18px)",
            height: 4,
            backgroundColor: "var(--brand-primary-500)",
            opacity: 1,
            transform: "translateX(-9px)",
            zIndex: -100,
        },
    },
    "& .MuiSlider-rail": {
        backgroundColor: "var(--charcoal-200)",
        transform: "scaleX(1.05)",
        top: "initial",
    },
}));

export default function CustomizedSlider(props: SliderProps) {
    return <CustomSlider valueLabelDisplay='auto' aria-label="ios slider" defaultValue={0} {...props} />;
}
