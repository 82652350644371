import React, { useState } from "react";
import styles from "../styles/CompanySpotlight.module.css";
import Button from "./button";
import { CompanySpotlight as CompanySpotlightProp } from "../typescript/components";
import { brandPrimary500Color } from "../sdk/utils";

export default function CompanySpotlight({
  company_spotlight: spotlights,
}: {
  company_spotlight: CompanySpotlightProp;
}) {
  const [spotlight, setSpotlight] = useState(spotlights?.companies?.[0]);
  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };
  if (!spotlight) return <></>;
  const reverse = spotlight.media_position === "Left";
  const getButtonStyle = () => {
    if (spotlight.cta.style === "solid") {
      return {
        propBackgroundColor: "var(--charcoal-500)",
        propBorderColor: "var(--charcoal-500)",
        propHoverBackgroundColor: "transparent",
        propTextColor: "var(--white)",
        propHoverText: "var(--charcoal-500)",
      };
    }

    if (spotlight.cta.style === "brand_specific") {
      return {
        propBackgroundColor: "var(--brand-primary-500)",
        propBorderColor: "var(--brand-primary-500)",
        propHoverBackgroundColor: "transparent",
        propTextColor: "var(--white)",
        propHoverText: "var(--brand-primary-500)`}",
      };
    }

    return {
      propBackgroundColor: brandPrimary500Color[spotlight.cta.style],
      propBorderColor: brandPrimary500Color[spotlight.cta.style],
      propHoverBackgroundColor: "transparent",
      propTextColor: "var(--white)",
      propHoverText: brandPrimary500Color[spotlight.cta.style],
    };
  };
  return (
    <>
      <div className={styles.tabWrapper}>
        {spotlights.companies.map((s, index) => (
          <div
            className={`${styles.tabItem} ${s.tab_title === spotlight.tab_title && styles.tabItemSelected}`}
            key={index}
            onClick={() => setSpotlight(s)}
          >
            {s.tab_title}
          </div>
        ))}
      </div>
      <div className={`${styles.container} ${reverse ? styles.reverse : ""}`}>
        {spotlight.media && (
          <div className={styles.imageContainer}>
            <img
              className={styles.imageIcon}
              loading="lazy"
              alt={spotlight.media.description}
              src={appendWidthParam(spotlight.media.url, 688)}
            />
          </div>
        )}

        <div className={styles.contentWrapper}>
          {spotlight.logo && (
            <img
              className={styles.imageLogo}
              alt={spotlight.logo.description}
              src={appendWidthParam(spotlight.logo.url, 688)}
            />
          )}
          {spotlight.title && (
            <h3 className={styles.title}>{spotlight.title}</h3>
          )}

          {spotlight.description && (
            <p className={styles.description}>{spotlight.description}</p>
          )}
          {spotlight.cta && (
            <Button
              text={spotlight.cta.title}
              showIcon
              propWidth="250px"
              link={spotlight.cta.url}
              {...getButtonStyle()}
            />
          )}
        </div>
      </div>
    </>
  );
}
