import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/Testimonial.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Testimonial as TestimonialProp, TestimonialItem } from "../typescript/components";
import { Chevron } from "./icon/chevron";
import { generateRandomId } from "../helper/utils";

interface TestimonialProps {
  testimonial: TestimonialProp;
}

const Testimonial: React.FC<TestimonialProps> = ({ testimonial }) => {
  const data = testimonial.testimonials;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const slidesPerView = 1;
  const shouldShowNavigation = data.length > slidesPerView;
  const [paginationId, setPaginationId] = useState(generateRandomId());

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.params) {
      const { navigation, pagination } = swiperRef.current.params;
      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;
        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
      if (pagination) {
        pagination.el = paginationRef.current;
        swiperRef.current.pagination?.destroy();
        swiperRef.current.pagination?.init();
        swiperRef.current.pagination?.update();
      }
    }
  }, [shouldShowNavigation, swiperRef.current]);

  useEffect(() => {
    const updateSlideHeights = () => {
      if (!swiperRef.current) return;
      const slides = swiperRef.current.slides as unknown as Element[];
      let maxHeight = 0;
  
      slides.forEach((slide: Element) => {
        const slideHeight = (slide as HTMLElement).clientHeight;
        if (slideHeight > maxHeight) {
          maxHeight = slideHeight;
        }
      });
  
      slides.forEach((slide: Element) => {
        (slide as HTMLElement).style.height = `${maxHeight}px`;
      });
    };
  
    if (swiperRef.current) {
      swiperRef.current.on('slideChange', updateSlideHeights);
      swiperRef.current.on('resize', updateSlideHeights);
      updateSlideHeights();
    }
  
    return () => {
      if (swiperRef.current) {
        swiperRef.current.off('slideChange', updateSlideHeights);
        swiperRef.current.off('resize', updateSlideHeights);
      }
    };
  }, [swiperRef.current]);

  return (
    <div className={`${styles.container} testimonial`}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
        onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
        slidesPerView={slidesPerView}
        slidesPerGroup={1}
        loop={shouldShowNavigation}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{
          el: `#${paginationId}`,
          clickable: true,
          enabled: shouldShowNavigation,
          dynamicBullets: true,
        }}
        autoHeight={false} // Disable autoHeight to use dynamic height
      >
        {data.length > 0 &&
          data.map((item: TestimonialItem, index: number) => {
            const backgroundImage = item.background_image?.url
              ? `url("${item.background_image.url}?width=1440&auto=webp&fit=crop,smart")`
              : null;
            return (
              <SwiperSlide
                key={`${item.title}-${index}`}
                className={styles.slide}
              >
                <div
                  className={[
                    styles.wrapper,
                    item.background_image?.url
                      ? styles.hasBackground
                      : styles.noBackground,
                  ].join(" ")}
                  style={backgroundImage ? { backgroundImage } : {}}
                >
                  <h3 className={styles.title}>{item.title}</h3>
                  {item.sub_title ? (
                    <p className={styles.subtitle}>{item.sub_title}</p>
                  ) : (
                    ""
                  )}
                  <span className={styles.quoteSymbol}>&ldquo;</span>
                  <h4 className={styles.quote}>{item.quote}</h4>
                  {item.image && (
                    <div className={styles.avatar}>
                      <img
                                    src={item.image.url + "?width=55&height=55&auto=webp&fit=crop"}
                        alt={item.image.description}
                      />
                    </div>
                  )}
                  {item.name && <p className={styles.name}>{item.name}</p>}
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {shouldShowNavigation && (
        <div className={styles.navigation}>
          <button ref={prevRef} className={styles.prevButton}>
            <Chevron direction="left" color="white" size={20} />
          </button>
          <div
            className={styles.pagination}
            ref={paginationRef}
            id={paginationId}
          ></div>
          <button ref={nextRef} className={styles.nextButton}>
            <Chevron direction="right" color="white" size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Testimonial;