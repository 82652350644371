import React, { useState } from "react";
import styles from "../styles/VerticalCard.module.css";
import { VerticalCard as VerticalCardProp } from "../typescript/components";
import i18n from "../sdk/i18n";

export default function VerticalCard({
  vertical_card,
  isCardTeamStyle,
}: {
  vertical_card: VerticalCardProp;
  isCardTeamStyle?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);

  const iconSrc =
    isHovered && vertical_card.icon_hover && vertical_card.icon_hover.url
      ? vertical_card.icon_hover.url
      : vertical_card.icon && vertical_card.icon.url
        ? vertical_card.icon.url
        : "";

  return (
    <div
      className={[
        styles.verticalCard,
        isCardTeamStyle ? styles.cardTeamWrapper : "",
      ]
        .filter(Boolean)
        .join(" ")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (vertical_card.link && vertical_card.link[0]?.url) {
            window.location.href = vertical_card.link[0]?.url;
          }
        else if (vertical_card.external_link) {
            let externalLink = vertical_card.external_link;
            if (externalLink.startsWith("/")) {
                externalLink = `/${i18n.language}${externalLink}`;
            }
            window.location.href = externalLink;
          }
      }}
      style={{
        cursor:
              (vertical_card.link && vertical_card.link[0]?.url) || (vertical_card.external_link) ? "pointer" : "default",
      }}
    >
      {vertical_card.background_image && vertical_card.background_image.url && (
        <img
          className={styles.backgroundImage}
          alt={vertical_card.background_image.description || "Background Image"}
                  src={vertical_card.background_image.url + "?width=350&auto=webp&fit=crop"}
        />
      )}
      <div className={styles.content}>
        {iconSrc && (
          <img
            className={styles.icon}
            loading="lazy"
            alt={vertical_card.icon?.description || "Icon"}
            src={iconSrc}
          />
        )}
        <b className={styles.description}>{vertical_card.title}</b>
      </div>
      {(isHovered || isCardTeamStyle) && vertical_card.slogan && (
        <span className={styles.slogan}>{vertical_card.slogan}</span>
      )}
    </div>
  );
}
