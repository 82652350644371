import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getWhitePaperDetail } from "../helper";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { WhitePaperRes } from "../typescript/pages";
import styles from "../styles/WhitePaper.module.css";
import { Helmet } from "react-helmet-async";
import HeroBanner from "../components/hero-banner";
import { HeroBanner as HeroBannerProp } from "../typescript/components";
import HeroTitle from "../components/hero-title";
import {
  EntryProps,
  HeroTitle as HeroTitleProp,
} from "../typescript/components";
import { useTranslation } from "react-i18next";

export default function Video({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
  const { whitePaperId } = useParams();
  const history = useNavigate();
  const [getEntry, setEntry] = useState({
    video: {} as WhitePaperRes,
  });
  const [error, setError] = useState(false);
  const { i18n } = useTranslation();
  async function fetchData() {
    try {
      const entryUrl = whitePaperId ? `/resources/video/${whitePaperId}` : "/";
      const video = await getWhitePaperDetail(entryUrl);
      if (!video) setError(true);
      setEntry({ video });
      entry({ video: [video] });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData();
    if (error) history(`/${i18n.language}/404`);
  }, [whitePaperId, lpTs, error, i18n.language]);

  const { video } = getEntry;

  const heroBannerData = {
    banner_eyebrow: "VIDEO",
    banner_title: video.title,
    banner_title_alt: "",
    display_options: {
      align_image: "Right",
      align_text: "Left",
      background_color: "Light",
      breadcrumb: true,
      show_button: false,
      split_50_50: true,
    },
    header_tag_type: "H1",
    //banner_description: "",
    //banner_logo: "",
    banner_media: video.featured_image,
  } as HeroBannerProp;

  const heroTitleData = {
    align_text: "Left",
    bottom_border: true,
    content: "",
    eyebrow: "VIDEO",
    header_tag_type: "H1",
    show_breadcrumb: true,
    title: video.title,
  } as HeroTitleProp;
  
  const meta_title = video?.seo?.meta_title;
  const meta_description = video?.seo?.meta_description;
  const keywords = video?.seo?.keywords;

  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
<meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.outerWrapper}>
        {video.featured_image && video.featured_image.url ? (
          <HeroBanner
            key="contactPageTitle"
            hero_banner={heroBannerData}
          ></HeroBanner>
        ) : (
          <HeroTitle
            key="contactPageTitle"
            hero_title={heroTitleData}
          ></HeroTitle>
        )}
        <div className={styles.moduleWrapper}>
          <div className={styles.root}>
            <section className={styles.virtualTour}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: video.content,
                }}
              />
            </section>
            <div className={styles.divider} />
            <div className={styles.taxonomyWrapper}></div>
          </div>
        </div>
      </div>
    </>
  );
}
