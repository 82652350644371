import React from "react";
import Skeleton from "react-loading-skeleton";
import { Presenter, PresentersProps } from "../typescript/components";
import styles from "../styles/Presenters.module.css";

export default function Presenters({
  presenters,
}: {
  presenters: PresentersProps;
}) {
  return (
    <>
      <h5 className={styles.heading}>Presenters:</h5>
      <div className={styles.wrapper}>
        {presenters.map((presenter: Presenter) => (
          <div className={styles.presenter} key={presenter.name}>
            <img
              className={`${styles.avatar} ${presenter?.avatar?.url ? styles["avatar-loaded"] : styles["avatar-hidden"]}`}
              alt={presenter?.avatar?.title}
              src={presenter?.avatar?.url}
            />
            <div className={styles.information}>
              {presenter.name ? (
                <span className={styles.name}>{presenter.name}</span>
              ) : (
                <Skeleton width={100} />
              )}
              {presenter.title ? (
                <span className={styles.title}>{presenter.title}</span>
              ) : (
                <Skeleton width={100} />
              )}
              {presenter.company ? (
                <span className={styles.company}>{presenter.company}</span>
              ) : (
                <Skeleton width={100} />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
