import React from "react";
import styles from "../styles/FullWidthMedia.module.css";
import { FullWidthMedia as FullWidthMediaProp } from "../typescript/components";
import WistiaVideo from "./wistia-video";

export default function FullWidthMedia({
  full_width_media,
}: {
  full_width_media: FullWidthMediaProp;
}) {
  const isVideo = (filename: string) => {
    return /\.(mp4|webm|ogg)$/i.test(filename);
  };

  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };
  return (
    <>
      {full_width_media && full_width_media.media ? (
        <div className={styles.wrapper}>
          {isVideo(full_width_media.media.filename) ? (
            <video autoPlay muted loop playsInline className={styles.backgroundMedia}>
              <source src={full_width_media.media.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={appendWidthParam(full_width_media.media?.url || "", 700)}
              alt={full_width_media.media?.description}
              className={styles.backgroundMedia}
            />
          )}
        </div>
      ) : (
        full_width_media?.wistia && (
          <div className={styles.wrapper}>
            <WistiaVideo embedUrl={full_width_media.wistia} responsive={true} />
          </div>
        )
      )}
    </>
  );
}
