import React from 'react';

type Direction = 'left' | 'right' | 'up' | 'down';

interface ChevronParams {
    direction: Direction; 
    color?: string; 
    size?: number
    onClick?: (event: any) => void;
}


export const Chevron = ({ direction, color, size, onClick }: ChevronParams) => {
    const chevronColor = color || 'var(--charcoal-500)'; // Use CSS variable or fallback color

    if (direction === 'right') {
        return (<svg onClick={onClick} width={size || 20} height={size || 20} viewBox="0 0 20 20" style={{cursor: 'pointer'}} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.50391 18.3008L14.4963 10.0001L5.50391 1.69946" stroke={chevronColor} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)
    }
    if (direction === 'left') {
        return (<svg onClick={onClick} width={size || 20} height={size || 20} viewBox="0 0 20 20" style={{cursor: 'pointer'}} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4961 1.69929L5.50368 9.99997L14.4961 18.3007" stroke={chevronColor} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)
    }

    return (<></>)
}