import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/EventSpotlightBanner.module.css";
import Button from "./button";
import { EventSpotlightBannerProps } from "../typescript/components";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import WistiaVideo from "./wistia-video";
import { pauseWistiaVideos } from "../helper/wistiaUtils";
import { useLocation } from "react-router-dom";
import { generateRandomId } from "../helper/utils";

export default function EventSpotlightBanner({
  event_spotlight_banner,
}: {
  event_spotlight_banner: EventSpotlightBannerProps;
}) {
  const slidesPerView = 1;
  const totalSlides = event_spotlight_banner?.items?.length;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const contentCarouselRef = useRef<HTMLDivElement | null>(null);
  const contentImgRef = useRef<HTMLDivElement | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [activeTab, setActiveTab] = useState(0)
  const [paginationPosition, setPaginationPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const shouldShowNavigation = slidesPerView < totalSlides;
  const [paginationId, setPaginationId] = useState(generateRandomId());
  const calculatePaginationPosition = (index: number) => {
    if (contentRefs?.current?.[index]?.offsetHeight) {
      setPaginationPosition((contentRefs?.current?.[index]?.offsetHeight || 0));
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        // Calculate scroll position to be 20px higher than the element
        const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
      }
  }, [location]);

  // Handle Swiper slide change
  const handleSlideChange = (swiper: SwiperType) => {
    calculatePaginationPosition(swiper.realIndex);
    setActiveTab(swiper.realIndex)
    pauseWistiaVideos();
  };

  useLayoutEffect(() => {
    setIsMobile(window.innerWidth <= 767);
    calculatePaginationPosition(activeTab);
  }, [window.innerWidth]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.params) {
      const { navigation, pagination } = swiperRef.current.params;
      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;
        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
      if (pagination) {
        pagination.el = paginationRef.current;
        swiperRef.current.pagination?.destroy();
        swiperRef.current.pagination?.init();
        swiperRef.current.pagination?.update();
      }
    }
  }, [shouldShowNavigation, swiperRef.current]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
      calculatePaginationPosition(activeTab);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const shadow = event_spotlight_banner.shadow ? styles.shadow : "";
  const dark_background = event_spotlight_banner.dark_background
    ? styles.darkBackground
    : styles.lightBackground;

  const getButtonProps = () => {
    if (event_spotlight_banner.dark_background) {
      return {
        propBorderColor: `${event_spotlight_banner?.button.style === "Solid"
            ? `var(--white)`
            : `var(--brand-primary-500)`
          }`,
        propBackgroundColor: `${event_spotlight_banner?.button.style === "Solid"
            ? `transparent`
            : `var(--brand-primary-500)`
          }`,
        propHoverBackgroundColor: "var(--white)",
        propTextColor: `${event_spotlight_banner?.button.style === "Solid"
            ? `var(--white)`
            : `var(--white)`
          }`,
        propHoverText: `${event_spotlight_banner?.button.style === "Solid"
            ? `var(--charcoal-500)`
            : `var(--brand-primary-500)`
          }`,
      };
    }
    return {
      propBorderColor: `${event_spotlight_banner?.button.style === "Solid"
          ? `var(--charcoal-500)`
          : `var(--brand-primary-500)`
        }`,
      propBackgroundColor: `${event_spotlight_banner?.button.style === "Solid"
          ? `var(--charcoal-500)`
          : `var(--brand-primary-500)`
        }`,
      propHoverBackgroundColor: "var(--white)",
      propTextColor: "var(--white)",
      propHoverText: `${event_spotlight_banner?.button.style === "Solid"
          ? `var(--charcoal-500)`
          : `var(--brand-primary-500)`
        }`,
    };
  };

  return (
    <div id={event_spotlight_banner?.anchor || event_spotlight_banner?.title} key={event_spotlight_banner?.title} className={`${styles.wrapper} ${shadow} ${dark_background}`}>
      <div className={styles.innerWrap}>
        <div className={styles.content}>
          {event_spotlight_banner?.title &&
            (event_spotlight_banner?.button.link.url ? (
              <a className={styles.title}>{event_spotlight_banner?.title}</a>
            ) : (
              <h3 className={styles.title}>{event_spotlight_banner?.title}</h3>
            ))}
          {event_spotlight_banner?.description && (
            <span className={styles.subtitle}>
                {parse(DOMPurify.sanitize(event_spotlight_banner?.description))}
            </span>
          )}
          {event_spotlight_banner?.button.link.title && (
            <Button
              text={event_spotlight_banner?.button.link.title}
              showIcon
              propWidth="250px"
              {...getButtonProps()}
              link={
                event_spotlight_banner?.button?.link?.url ??
                event_spotlight_banner?.button?.link?.href
              }
            />
          )}
        </div>
        <div className={styles.swiperWrap} ref={contentCarouselRef}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
            slidesPerView={slidesPerView}
            spaceBetween={2}
            className={`${styles.spotlightCarousel} spotlight-swiper`}
            loop={shouldShowNavigation}
            navigation={{
              nextEl: nextRef.current,
              prevEl: prevRef.current,
            }}
            pagination={{
              el: `#${paginationId}`,
              clickable: true,
              dynamicBullets: true,
              enabled: shouldShowNavigation,
            }}
            onSlideChange={handleSlideChange}
          >
            {event_spotlight_banner.items?.length &&
              event_spotlight_banner.items?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={styles.contentCarousel}
                    style={{
                      gap: `${isMobile && shouldShowNavigation ? "78px" : "var(--md)"
                        }`,
                    }}
                  >
                    <div className={styles.contentImg} >
                      {!slide?.media?.url && slide?.wistia ? (
                        <div ref={(el) => (contentRefs.current[index]) = el}>
                          <WistiaVideo
                            embedUrl={slide.wistia}
                            responsive={true}
                          />
                        </div>
                      ) : (
                        slide?.media && (
                          <img
                            ref={(el) => (contentRefs.current[index]) = el}
                            className={styles.shadowImage}
                            src={slide?.media?.url + "?width=700&auto=webp&fit=crop,smart"}
                            alt={slide?.media?.description}
                          />
                        )
                      )}
                    </div>
                    {slide?.title && (
                      <div className={styles.contentText}>
                        <h4
                          className={styles.titleCarousel}
                          dangerouslySetInnerHTML={{
                            __html: slide?.title,
                          }}
                        ></h4>
                        {slide?.description && (
                          <p
                            className={styles.descriptionCarousel}
                            dangerouslySetInnerHTML={{
                              __html: slide?.description,
                            }}
                          ></p>
                        )}
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            {shouldShowNavigation && (
              <div
                className={styles.navigationWrapper}
                style={{
                  top: `${paginationPosition}px`,
                }}
              >
                <button ref={prevRef} className={styles.prevButton}>
                  <Chevron
                    direction="left"
                    color="var(--chevron-color, var(--charcoal-500))"
                    size={20}
                  />
                </button>
                <div ref={paginationRef} className={`${styles.pagination}`} id={paginationId}></div>
                <button ref={nextRef} className={styles.nextButton}>
                  <Chevron
                    direction="right"
                    color="var(--chevron-color, var(--charcoal-500))"
                    size={20}
                  />
                </button>
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
