import React, { useEffect, useRef, useState } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "../styles/ResourceListGrid.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import Button from "./button";
import BlogCard from "./blog-card";
import { handleSwiperBreakpoint } from "../sdk/utils";
import { generateRandomId } from "../helper/utils";
import i18n from "../sdk/i18n";
export default function ResourceListGrid({
  resource_grid,
  taxonomies,
}: {
  resource_grid: ResourceGridProp;
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
}) {
  const {
    eyebrow,
    display_eyebrow,
    title,
    description,
    cta,
    display_cta,
    featured_resources,
    component_type,
  } = resource_grid;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const [paginationId, setPaginationId] = useState(generateRandomId());

  const paginationRef = useRef(null);
  const totalSlides = featured_resources.length;
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState<number>(3);
  const shouldShowNavigation =
        currentSlidesPerView < totalSlides && totalSlides > 1;

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.language);
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

    useEffect(() => {
      if (swiperRef.current && swiperRef.current.params) {
        const { navigation, pagination } = swiperRef.current.params;
        if (navigation) {
          navigation.prevEl = prevRef.current;
          navigation.nextEl = nextRef.current;
          swiperRef.current.navigation?.destroy();
          swiperRef.current.navigation?.init();
          swiperRef.current.navigation?.update();
        }
        if (pagination) {
          pagination.el = paginationRef.current;
          swiperRef.current.pagination?.destroy();
          swiperRef.current.pagination?.init();
          swiperRef.current.pagination?.update();
        }
      }
    }, [shouldShowNavigation, swiperRef.current]);

  return (
    <section className={`${styles.container} ${styles.resourceGridContainer}`}>
      <div className={styles.content}>
        {eyebrow && display_eyebrow && (
          <span className={styles.eyeBrow}>{eyebrow}</span>
        )}
        {title && <h3 className={styles.resourceCarouselTitle}>{title}</h3>}
        {description && (
          <div className={styles.sentencesAboutCompany}>{description}</div>
        )}
        {cta && display_cta && cta.title && (cta.url ?? cta.href) && (
          <Button
            text={cta.title}
            showIcon
            propWidth="250px"
            propBackgroundColor="var(--brand-primary-500)"
            propHoverBackgroundColor="var(--white)"
            propTextColor="var(--white)"
            propHoverText="var(--brand-primary-500)"
            link={cta.url ?? cta.href}
          />
        )}
      </div>
      <div className={`swiper-grid ${styles.grid}`}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          loop={true}
          onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
          onBreakpoint={(swiper: SwiperType) =>
            handleSwiperBreakpoint(swiper, setCurrentSlidesPerView)
          }
          spaceBetween={1}
          centeredSlides={false}
          navigation={{
            nextEl: prevRef.current,
            prevEl: nextRef.current,
          }}
          pagination={{
            el:  `#${paginationId}`,
            clickable: true,
            enabled: shouldShowNavigation,
            dynamicBullets: true,
          }}
          breakpoints={{
            320: {
              // Mobile screens
              slidesPerView: 1.4,
            },
            768: {
              // Tablets
              slidesPerView: 2.4,
            },
            1024: {
              // Tablets
              slidesPerView: 1.8,
            },
            1200: {
              // Desktop
              slidesPerView: 3,
            },
          }}
          className={`${styles.slider} ${component_type.toLowerCase().replace(" ", "-")}`}
        >
          {featured_resources.length > 0 &&
            featured_resources.map((item, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                    <BlogCard key={`BlogCard-${currentLanguage}`} blog_post={item} component_type={component_type} />
              </SwiperSlide>
            ))}
        </Swiper>
        {shouldShowNavigation && (
          <div className={styles.navigationWrapper}>
            <button ref={prevRef} className={styles.prevButton}>
              <Chevron
                direction="left"
                color="var(--chevron-color, var(--charcoal-500))"
                size={20}
              />
            </button>
            <div className={styles.pagination} ref={paginationRef} id={paginationId}></div>
            <button ref={nextRef} className={styles.nextButton}>
              <Chevron
                direction="right"
                color="var(--chevron-color, var(--charcoal-500))"
                size={20}
              />
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
