import React from "react";
import { createTheme, Pagination } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { PaginationProps } from "@mui/material/Pagination";
import { currentBrandPrimary500 } from "../../sdk/utils";

interface CustomizedPaginationProps {
  count: number;
  page: number;
  onChange?:
    | ((event: React.ChangeEvent<unknown>, page: number) => void)
    | undefined;
  color?: PaginationProps["color"];
  size?: PaginationProps["size"];
  className?: string | undefined;
}

const paginationTheme = createTheme({
  palette: {
    primary: {
      light: currentBrandPrimary500,
      main: currentBrandPrimary500,
      dark: currentBrandPrimary500,
    },
  },
});

export default function CustomizedPagination({
  count,
  page,
  onChange,
  color = "primary",
  size = "large",
  className,
}: CustomizedPaginationProps) {
  return (
    <ThemeProvider theme={paginationTheme}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        color={color}
        size={size}
        className={className}
      />
    </ThemeProvider>
  );
}
