import { extractWistiaIdFromHtmlSnippet } from "../sdk/utils";

interface WistiaVideoInstances {
  [key: string]: { pause: () => void } | null;
}

const wistiaInstances: WistiaVideoInstances = {};

const loadWistiaScripts = (() => {
  let isLoaded = false;
  return () => {
    if (isLoaded) return;
    isLoaded = true;
    const script = document.createElement("script");
    script.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script.async = true;
    document.head.appendChild(script);
  };
})();

export const handleWistiaInstance = (embedUrl: string) => {
  const videoId = extractWistiaIdFromHtmlSnippet(embedUrl);

  if (!videoId) return;
  if (!window._wq) window._wq = [];

  window._wq.push({
    id: videoId,
    onReady: (videoInstance: any) => {
      if (videoInstance) {
        wistiaInstances[videoId] = videoInstance;
      }
    },
  });

  loadWistiaScripts();
};

export const pauseWistiaVideos = () => {
  Object.values(wistiaInstances).forEach((video) => {
    if (video && typeof video.pause === "function") {
      video.pause();
    }
  });
};

// Just in case
// Clear the stored video instance when it’s no longer needed to prevent memory leaks
export const removeWistiaInstance = (videoId: string) => {
  delete wistiaInstances[videoId];
};
