import React, { useEffect, useState } from "react";
import styles from "../styles/TabBar.module.css";
import { TabBarProps } from "../typescript/components";
import { generateUniqueId, handleScrollToHash } from "../helper/utils";
import "swiper/css/navigation";
import { Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function TabBar({ tab_bar }: { tab_bar: TabBarProps }) {
  const currentTab = tab_bar?.tabs?.findIndex(tab => generateUniqueId(tab.anchor, "anchor") === location.hash.substring(1))
  const [activeTab, setActiveTab] = useState(currentTab);

  const handleScroll = (id: string) => {
    location.hash = id;
  };
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabsFlexible}>
        <div className={styles.horizontalTabWrapper}>
          <Swiper
            modules={[Scrollbar, A11y]}
            simulateTouch={false}
            allowTouchMove={false}
            slidesPerView={"auto"}
            spaceBetween={0}
            className={`${styles.swiper} tab-content-swiper`}
            centeredSlides
            centeredSlidesBounds
            loop={false}
            breakpoints={{
              0: {
                simulateTouch: true,
                allowTouchMove: true
              },
              640: {
                simulateTouch: false,
                allowTouchMove: false
              }
            }}
          >
            {tab_bar.tabs.map((tab, index) => (
              <SwiperSlide
                key={index}
                className={`${styles.horizontalTab} ${activeTab === index ? styles.activeTab : ""}`}
              >
                  <div
                    onClick={() => {
                        handleScroll(generateUniqueId(tab.anchor, "anchor"))
                        setActiveTab(index)
                      }
                    }
                    className={styles.tab}
                  >
                    {tab.title}
                  </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>
    </div>
  );
}
