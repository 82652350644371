import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { getAboutRes } from "../helper";
import { EntryProps } from "../typescript/components";
import { Page } from "../typescript/pages";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { handleScrollToHash } from "../helper/utils";
// Import the useLocale hook

export default function About({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const lpTs = useLivePreviewCtx();
  const [getEntries, setEntries] = useState({} as Page);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const entryUrl = location.pathname.startsWith(`/${i18n.language}`)
    ? location.pathname.replace(`/${i18n.language}`, "")
    : location.pathname;

  async function fetchData() {
    try {
      const result = await getAboutRes(entryUrl);
      if (!result) {
        setError(true);
      } 
      setEntries(result);
      entry({ page: [result] });
    } catch (error) {
      console.error("Error: ", error);
      setError(true);
    }
    }

    useEffect(() => {
        if (location.hash) {
            const hash = location.hash.substring(1);
            const element = document.getElementById(hash);

            if (!element) {
                // Observe DOM changes until the element is found
                const observer = new MutationObserver(() => {
                    const target = document.getElementById(hash);
                    if (target) {
                        handleScrollToHash(hash);
                        observer.disconnect(); // Stop observing once the target is found
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });

                return () => observer.disconnect();
            } else {
                handleScrollToHash(hash);
            }
        }
    }, [location.hash]);

  
  useEffect(() => {
    if (error) navigate(`/${i18n.language}/404`);
  }, [error])

  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;

  useEffect(() => {
    fetchData();
  }, [entryUrl, lpTs, error, i18n.language]);

  if (!getEntries) return <></>;
  return Object.keys(getEntries).length ? (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <RenderComponents
        pageComponents={getEntries?.page_components}
        contentTypeUid="page"
        entryUid={getEntries?.uid}
        locale={getEntries?.locale}
        taxonomies={getEntries?.taxonomies}
      />
    </>
  ) : (
    <Skeleton count={5} height={400} />
  );
}
