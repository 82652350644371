import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "../styles/ResourceOverview.module.css";
import { getAllResources, getResourcesOverviewRes } from "../helper";
import { TaxonomyRes, ResourcesOverviewPage, ResourcesRes } from "../typescript/pages";
import { PageTitleHeader, ResourcesOverviewEntryProps } from "../typescript/components";
import PageTitle from "../components/page-title";
import AsideFilter from "../components/aside-filter";
import BlogCard from "../components/blog-card";
import { Helmet } from "react-helmet-async";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import ResourceGrid from "../components/resource-grid";
import { useTranslation } from "react-i18next";
import CustomizedPagination from "../components/customized-mui/pagination";
import { useNavigate } from "react-router-dom";

export default function ResourcesOverview({
  entry,
}: {
  entry: ({ resources_overview }: ResourcesOverviewEntryProps) => void;
}) {
  const [entries, setEntries] = useState<ResourcesOverviewPage>(
    {} as ResourcesOverviewPage
  );
  const [resources, setResources] = useState<ResourcesRes[]>([]);
  const [filteredResources, setFilteredResources] = useState<ResourcesRes[]>([]);
  const [error, setError] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate()
  const [availableFilters, setFilters] = useState({
    typeFilter: [] as TaxonomyRes[],
  });
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;

  // Memoized function to fetch data
  const fetchData = useCallback(async () => {
    try {
      const [
        result,
        allResources,
      ] = await Promise.all([
        getResourcesOverviewRes("/resources"),
        getAllResources(),
      ]);

      const separatedResources = {
        webinarsList: allResources.filter((item: any) => item.type === "Webinar"),
        whitePapersList: allResources.filter((item: any) => item.type === "Whitepaper"),
        articlesList: allResources.filter((item: any) => item.type === "Article"),
        caseStudiesList: allResources.filter((item: any) => item.type === "Case Study"),
        videosList: allResources.filter((item: any) => item.type === "Video"),
        newsList: allResources.filter((item: any) => item.type === "News"),
        eventsList: allResources.filter((item: any) => item.type === "Event"),
      };

      const combinedResources = [
        ...separatedResources.webinarsList.map((item: any) => ({ ...item, cta: "View Webinar" })),
        ...separatedResources.whitePapersList.map((item: any) => ({ ...item, cta: "View Whitepaper" })),
        ...separatedResources.articlesList.map((item: any) => ({ ...item, cta: "View Article" })),
        ...separatedResources.caseStudiesList.map((item: any) => ({ ...item, cta: "View Case Study" })),
        ...separatedResources.videosList.map((item: any) => ({ ...item, cta: "Watch Video" })),
        ...separatedResources.newsList.map((item: any) => ({ ...item, cta: "View News" })),
        ...separatedResources.eventsList.map((item: any) => ({ ...item, cta: "View Event" })),
      ];
      if (!combinedResources?.length) return navigate(`/${i18n.language}/404`);
      setResources(combinedResources);
      setFilteredResources(combinedResources);

      const typeFilter: TaxonomyRes[] = [
        { name: "Article", uid: "article", parent_uid: "Type", depth: 1, order: 0 },
        { name: "Case Study", uid: "case study", parent_uid: "Type", depth: 1, order: 0 },
        { name: "Event", uid: "event", parent_uid: "Type", depth: 1, order: 0 },
        { name: "News", uid: "news", parent_uid: "Type", depth: 1, order: 0 },
        { name: "Video", uid: "video", parent_uid: "Type", depth: 1, order: 0 },
        { name: "Webinar", uid: "webinar", parent_uid: "Type", depth: 1, order: 0 },
        { name: "Whitepaper", uid: "whitepaper", parent_uid: "Type", depth: 1, order: 0 },
      ];

      const checkFilterExistence = (resources: ResourcesRes[], filter: TaxonomyRes[] | undefined, filterKey: string): TaxonomyRes[] => {
        if (!filter) return [];
        const uniqueTypes = new Set(resources.map((resource) => resource.type?.toLowerCase()).filter(Boolean));
        return filter.filter((item) => uniqueTypes.has(item.uid.toLowerCase()));
      };

      setFilters({
        typeFilter: checkFilterExistence(combinedResources, typeFilter, "type"),
      });

      setEntries(result || {});
      entry({ resources_overview: [result] });

    } catch (error) {
      setError(true);
      console.error(error);
    }
  }, [entry]);

  // Effect to fetch data only on first render
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Refetch on language change
  useEffect(() => {
    fetchData();
  }, [i18n.language, fetchData]);

    const applyFilters = useCallback(
        (selectedFilters: {
            type: string[];
            technology: string[];
            service: string[];
            metal: string[];
            industry: string[];
        }) => {
            setPage(1); // Reset to the first page when filters change

            if (Object.values(selectedFilters).every((value) => value?.length === 0)) {
                // No filters selected, reset to all resources
                setFilteredResources(resources);
                return;
            }

            const filtered = resources.filter((resource) =>
                matchesSelectedFilters(resource, selectedFilters)
            );

            setFilteredResources(filtered);
        },
        [resources]
    );

  // Memoized filter match function
  const matchesSelectedFilters = useCallback((resource: ResourcesRes, selectedFilters: {
    type: string[];
    technology: string[];
    service: string[];
    metal: string[];
    industry: string[];
  }) => {
    return selectedFilters.type?.includes(resource.type.toLowerCase()) || resource.taxonomies?.some((taxonomy) =>
      (selectedFilters.technology.includes(taxonomy.term_uid) && taxonomy.taxonomy_uid === "technology") ||
      (selectedFilters.service.includes(taxonomy.term_uid) && taxonomy.taxonomy_uid === "service") ||
      (selectedFilters.metal.includes(taxonomy.term_uid) && taxonomy.taxonomy_uid === "metals") ||
      (selectedFilters.industry.includes(taxonomy.term_uid) && taxonomy.taxonomy_uid === "industry")
    );
  }, []);

  const pageTitleData = {
    title: entries?.header_title,
    description: entries?.header_description,
  } as PageTitleHeader;

  const meta_title = entries?.seo?.meta_title;
  const meta_description = entries?.seo?.meta_description;
  const keywords = entries?.seo?.keywords;

    console.log("test");
  const resource_grid = {
    title: entries?.title_case_study,
    description: entries?.description_case_study,
    featured_resources: entries?.featured_resources,
  } as ResourceGridProp;

  return (
    <>
      <Helmet>
        <title>{meta_title || 'Resources Overview'}</title>
        <meta name="description" content={meta_description || ''} />
        <meta name="keywords" content={keywords || ''} />
              <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.outerWrapper}>
        <PageTitle page_title={pageTitleData} />
        {entries?.featured_resources?.length > 0 && <ResourceGrid resource_grid={resource_grid} />}
        <div className={styles.resourceWrapper}>
          {entries?.show_filter && (
            <AsideFilter filter={{ types: availableFilters.typeFilter }} onApplyFilters={applyFilters} />
          )}
          <div className={styles.resultsWrapper}>
            <div className={styles.resultsFiltered}>
              {filteredResources.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => (
                <div key={index} className={styles.slide}>
                  <BlogCard blog_post={item} component_type="Resource List" />
                </div>
              ))}
            </div>
            {filteredResources.length > 0 && (
              <CustomizedPagination count={Math.ceil(filteredResources.length / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} className={styles.resultsFilteredPagination} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}