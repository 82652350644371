import React from "react";
import styles from "../styles/Snipe.module.css";
import { Snipe as SnipeProp } from "../typescript/components";
import Button from "./button";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';

export default function Snipe({ snipe }: { snipe: SnipeProp }) {
  return (
    <>
      {snipe && snipe.title && (
        <div className={styles.snipe}>
          <section className={styles.container}>
            <div className={styles.content}>
              <span className={styles.title}>{snipe.title}</span>
              <span className={styles.description}>
                              {parse(DOMPurify.sanitize(snipe.description))}
              </span>
            </div>
            {snipe && snipe.cta && snipe.title && snipe.cta.href &&(
              <Button
                text={snipe.cta.title}
                showIcon
                propWidth="250px"
                propBorderColor="var(--white)"
                propBackgroundColor="transparent"
                propHoverBackgroundColor="var(--white)"
                propHoverText="var(--charcoal-500)"
                propTextColor="var(--white)"
                link={snipe.cta.href}
              />
            )}
          </section>
        </div>
      )}
    </>
  );
}
