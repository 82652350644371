import React from "react";
import styles from "../styles/ProductOverview.module.css";
import { ProductOverview as ProductOverviewProp } from "../typescript/components";
import GenericSmallCard from "./generic-small-card";

export default function ProductOverview({
  product_overview,
  noGap = false
}: {
  product_overview: ProductOverviewProp;
  noGap: boolean
}) {
  const cards = product_overview?.small_card?.slice(0, 4); // take up to 4 cards
  return (
    <div className={styles.root}>
      <section className={`${styles.content} ${noGap ? styles.noGap : ''}`}>
        <h3 className={styles.overview}>{product_overview.title}</h3>
        <div className={styles.cardGrid}>
        {cards && cards.length > 0 && cards.map((card) => (
            <GenericSmallCard key={card.title} small_card={card} />
        ))}
        </div>
      </section>
      <section className={styles.loremIpsumDolorContainer}>
        <div
          dangerouslySetInnerHTML={{
            __html: product_overview.summary,
          }}
        />
      </section>
    </div>
  );
}
