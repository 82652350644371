import React, { useEffect, useState, useRef } from "react";
import styles from "../styles/MegaNav.module.css";
import { NavLink, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { NavigationMenu, PageReference } from "../typescript/response";
import { Chevron } from "./icon/chevron";
import Button from "./button";
import LanguageSelector from "./language-selector";
import { CSLocale } from "../typescript/components";

interface MegaNavProps {
  navMenu: NavigationMenu[];
  isMobile?: boolean;
  isOpenMobileSideBar?: boolean;
  handleCloseSideBar?: (arg: boolean) => void;
  showLanguageSelector?: boolean;
  languages: CSLocale[] | null;
  currentLocale: string; // Add currentLocale as a prop
  onLocaleChange: (newLocale: string) => void; // Add onLocaleChange callback as a prop
  locations?: PageReference;
  request_quote: PageReference;
  contact_us: PageReference;
}

export default function MegaNav({
  navMenu,
  isMobile = false,
  isOpenMobileSideBar = false,
  handleCloseSideBar,
  showLanguageSelector,
  languages,
  currentLocale,
  onLocaleChange,
  locations,
  request_quote,
  contact_us,
}: MegaNavProps) {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null); // State to track which menu is open
  const [isMenuLevelTwoOpened, setIsMenuLevelTwoOpened] = useState(false);
  const [menuLevelTwo, setMenuLevelTwo] = useState<NavigationMenu | null>(null);
  const [mobileCTAHeight, setMobileCTAHeight] = useState<number | null>(null);
  const mobileCTARef = useRef<HTMLDivElement | null>(null);
  const navMobileContainerL2Ref = useRef<HTMLDivElement | null>(null);
  const location =
    "/" +
    useLocation()
      ?.pathname?.split("/")
      ?.filter((p) => p)?.[0];
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Reference for detecting clicks outside the dropdown

  const selectMenu = (menu?: NavigationMenu) => {
    setIsMenuLevelTwoOpened(!isMenuLevelTwoOpened);
    if (menu) setMenuLevelTwo(menu);
  };

  const selectLink = () => {
    handleCloseSideBar && handleCloseSideBar(false);
  };

  const toggleDropdown = (index: number) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle dropdown visibility
  };

  const getDropdownBanner = (nav: NavigationMenu) => {
    const { row_image: rowImage, column_image: colImage, label } = nav;
    if (!rowImage?.image && !colImage?.image) return "";

    if (rowImage?.image) {
      const remainder = nav.sub_navigation.length % 3;
      const blankItemsNeeded = remainder === 0 ? 2 : 2 - remainder;
      const itemMissing = Array.from(
        { length: blankItemsNeeded },
        (_, index) => (
          <div key={`blank-${index}`} className={styles.megaMenuL2}></div>
        )
      );
      return (
        <>
          {itemMissing}
          <div key={`${label}-banner`}>
            <a href={rowImage.url || undefined}>
              <img
                className={styles.bannerImage}
                src={rowImage.image.url}
                alt={rowImage.image.title}
              />
            </a>
          </div>
        </>
      );
    }
    if (colImage?.image) {
      return (
        <>
          <div
            key={`${label}-banner`}
            className={styles.dropdownBannerCol}
            style={{
              gridRow: `1 / span ${Math.ceil(nav.sub_navigation.length / 2)}`,
            }}
          >
            <a href={colImage.url || undefined}>
              <img
                className={styles.bannerImage}
                src={colImage.image.url}
                alt={colImage.image.title}
              />
            </a>
          </div>
        </>
      );
    }
  };

  // Set the height of mobileCTA
  useEffect(() => {
    if (mobileCTARef.current) {
      setMobileCTAHeight(mobileCTARef.current.offsetHeight);
    }
    const handleResize = () => {
      if (mobileCTARef.current) {
        setMobileCTAHeight(mobileCTARef.current.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Prevent scrolling on the dropdown
  useEffect(() => {
    const stopScrollPropagation = (event: Event) => event.stopPropagation();

    const container = navMobileContainerL2Ref.current;
    if (container) {
      container.addEventListener("wheel", stopScrollPropagation);
      container.addEventListener("touchmove", stopScrollPropagation);
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", stopScrollPropagation);
        container.removeEventListener("touchmove", stopScrollPropagation);
      }
    };
  }, []);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenMenuIndex(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getHash = (hash: string | undefined) => {
    if (!hash) return "";
    if (hash.startsWith("#")) return hash;
    return `#${hash}`;
  };

  useEffect(() => {
    if (!isOpenMobileSideBar) setIsMenuLevelTwoOpened(false);
  }, [isOpenMobileSideBar]);

  useEffect(() => {
    if (isOpenMobileSideBar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpenMobileSideBar]);

  return !isMobile ? (
    <nav role="navigation" className={styles.links} ref={dropdownRef}>
      {navMenu && navMenu.length > 0 ? (
        navMenu.map((list, listIndex) => (
          <div key={`${list.label}-${listIndex}`} className={styles.navbarLink}>
            <NavLink
              {...(list.$?.label as {})}
              to={`/${currentLocale}${list.page_reference[0].url}`} // Add currentLocale dynamically
              className={
                location === `/${currentLocale}${list.page_reference[0].url}` ||
                openMenuIndex === listIndex
                  ? `${styles.mainLink} ${styles.active}`
                  : styles.mainLink
              }
              onClick={(e) => {
                if (
                  list.sub_navigation?.length > 0 &&
                  list.sub_navigation?.every(
                    (page) => page?.page_reference?.length > 0
                  )
                ) {
                  e.preventDefault(); // Prevent default navigation only if there are sub-navigations
                  toggleDropdown(listIndex); // Toggle dropdown
                } else {
                  setOpenMenuIndex(null);
                }
              }}
            >
              {list.label}
            </NavLink>
            {list.sub_navigation &&
              list.sub_navigation.length > 0 &&
              openMenuIndex === listIndex && (
                <div className={styles.dropdown}>
                  <div className={styles.megaMenu}>
                    <NavLink
                      className={styles.dropdownTitle}
                      to={`/${currentLocale}${list.page_reference[0].url}`}
                      onClick={() => {
                        toggleDropdown(listIndex);
                      }}
                    >
                      {list.label}{" "}
                      <Chevron
                        color="var(--primary-500)"
                        direction="right"
                        size={20}
                      />
                    </NavLink>
                    <section className={styles.linkGroup}>
                      <div className={styles.subLinks}>
                        {list.sub_navigation
                          ?.filter((page) => page?.page_reference?.length > 0)
                          .map((subItem, subIndex) => (
                            <div
                              key={`${list.label}-${listIndex}-${subIndex}`}
                              className={styles.megaMenuL2}
                              style={{
                                gap: subItem?.title_image?.url
                                  ? "var(--base)"
                                  : "var(--xs)",
                              }}
                            >
                              <NavLink
                                key={`${list.label}-${listIndex}-${subIndex}-link`}
                                to={`/${currentLocale}${
                                  subItem?.page_reference[0]?.url
                                }${getHash(subItem.hash)}`}
                                className={styles.multiSlide}
                                onClick={() => {
                                  toggleDropdown(listIndex);
                                }}
                              >
                                {subItem?.title_image?.url && (
                                  <span>
                                    <img
                                      className={styles.logoImageDropdown}
                                      src={subItem.title_image.url}
                                      alt=""
                                    />{" "}
                                    <br /> <br />
                                  </span>
                                )}
                                {subItem.label}
                                <div
                                  key={`${list.label}-${listIndex}-${subIndex}-description`}
                                  className={styles.Menul2description}
                                >
                                  {subItem.description}
                                </div>
                              </NavLink>
                            </div>
                          ))}
                        {getDropdownBanner(list)}
                      </div>
                    </section>
                  </div>
                </div>
              )}
          </div>
        ))
      ) : (
        <div className={styles.navbarLink}>
          <a>
            <Skeleton width={400} />
          </a>
        </div>
      )}
    </nav>
  ) : (
    <>
      <div
        className={`${styles.navMobileContainer} ${
          isOpenMobileSideBar ? styles.open : ""
        }`}
      >
        <nav
          style={{
            height: mobileCTAHeight
              ? `calc(100% - ${mobileCTAHeight}px - 72px)` //72px is the height of the header container mobile
              : "100%", // Fallback if height is not set yet
              overflowY: "scroll"
          }}
        >
          {navMenu && navMenu.length > 0 ? (
            navMenu.map((list, listIndex) => {
              return (
                <div
                  key={`${list.label}-${listIndex}`}
                  className={styles.navbarLink}
                  onClick={() => {
                    if (
                      list.sub_navigation?.length > 0 &&
                      list.sub_navigation?.every(
                        (page) => page?.page_reference?.length > 0
                      )
                    ) {
                      selectMenu(list);
                    }
                  }}
                >
                  {list.sub_navigation?.length > 0 &&
                  list.sub_navigation?.every(
                    (page) => page?.page_reference?.length > 0
                  ) ? (
                    <div
                      onClick={() => {
                        selectMenu(list);
                      }}
                      className={styles.menuItemWrapper}
                    >
                      <span className={styles.menuMobileLink}>
                        {list.label}
                      </span>
                      <Chevron
                        direction="right"
                        onClick={() => {
                          selectMenu(list);
                        }}
                      />
                    </div>
                  ) : (
                    <NavLink
                      {...(list.$?.label as {})}
                      to={`/${currentLocale}${list.page_reference[0].url}`} // Add currentLocale dynamically
                      className={`${styles.menuMobileLink} ${styles.menuItemWrapper}`}
                      onClick={selectLink}
                    >
                      {list.label}
                    </NavLink>
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.navbarLink}>
              <a>
                <Skeleton width={400} />
              </a>
            </div>
          )}
        </nav>
        {menuLevelTwo && (
          <nav
            ref={navMobileContainerL2Ref}
            className={`${styles.navMobileContainerL2} ${
              isOpenMobileSideBar && isMenuLevelTwoOpened ? styles.open : ""
            }`}
            style={{
              height: mobileCTAHeight
                ? `calc(100% - ${mobileCTAHeight}px - 72px)` //72px is the height of the header container mobile
                : "100%", // Fallback if height is not set yet
                overflowY: "scroll"
            }}
          >
            <div
              className={`${styles.navbarLink} ${styles.navbarLinkTitleL2}`}
              onClick={() => {
                setIsMenuLevelTwoOpened(false);
              }}
            >
              <div className={styles.menuItemL2Wrapper}>
                <Chevron color="var(--primary-500)" direction="left" />
                <NavLink
                  to={`/${currentLocale}${menuLevelTwo.page_reference?.[0]?.url}`}
                  className={styles.multiSlide}
                >
                  {menuLevelTwo.label}
                </NavLink>
              </div>
            </div>
            {menuLevelTwo?.sub_navigation
              ?.filter((page) => page?.page_reference?.length > 0)
              .map((list) => (
                <div
                  key={`${list.label}`}
                  className={`${styles.navbarLink} ${styles.navbarLinkL2}`}
                >
                  <div className={styles.menuItemWrapper}>
                    <NavLink
                      to={list?.hash ?`/${currentLocale}${list.page_reference?.[0]?.url}#${list?.hash}` :`/${currentLocale}${list.page_reference?.[0]?.url}`}
                      className={`${styles.multiSlide} ${styles.navbarLinkL2Item}`}
                      onClick={selectLink}
                    >
                      {list.label}
                      {list?.title_image?.url && (
                        <img
                          className={styles.logoImageDropdown}
                          src={list.title_image.url}
                          alt=""
                        />
                      )}
                    </NavLink>
                  </div>
                </div>
              ))}
          </nav>
        )}
        <div ref={mobileCTARef} className={styles.mobileCTA}>
          <div className={styles.mobileCTAButtons}>
            {request_quote?.page_reference?.[0]?.url && (
              <Button
                text={request_quote?.label ?? "Request a Quote"}
                showIcon
                propWidth="11.25rem"
                propBackgroundColor="var(--white)"
                propHoverBackgroundColor="var(--brand-primary-500)"
                propHoverText="var(--white)"
                propTextColor="var(--primary-500)"
                link={`/${currentLocale}${request_quote.page_reference[0].url}${
                  request_quote?.form_type
                    ? `?form_type=${request_quote?.form_type}`
                    : ""
                }`}
              />
            )}
            {contact_us?.page_reference?.[0]?.url && (
              <Button
                text={contact_us?.label ?? "Contact"}
                showIcon
                propWidth="11.25rem"
                propBackgroundColor="var(--primary-500)"
                propHoverBackgroundColor="transparent"
                propTextColor="var(--white)"
                link={`/${currentLocale}${contact_us.page_reference[0].url}${
                  contact_us?.form_type
                    ? `?form_type=${contact_us?.form_type}`
                    : ""
                }`}
              />
            )}
          </div>
          <div className={styles.mobileBottomWrapper}>
            {locations &&
            locations.page_reference &&
            locations.page_reference.length > 0 &&
            locations.page_reference[0]?.url ? (
              <span className={styles.mobileLocationsLink}>
                <NavLink
                  to={`/${currentLocale}${locations.page_reference[0].url}`}
                >
                  {locations.label}
                </NavLink>
              </span>
            ) : null}
            {showLanguageSelector && (
              <LanguageSelector
                languages={languages}
                currentLocale={currentLocale}
                onLocaleChange={onLocaleChange}
              ></LanguageSelector>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
