import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/TabContent.module.css";
import { TabContentProps } from "../typescript/components";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';
import "swiper/css/navigation";
import { Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";

export default function TabContent({
  tab_content,
}: {
  tab_content: TabContentProps;
}) {
  const [activeTab, setActiveTab] = useState(0); // Default to first tab
  const swiperRef = useRef<SwiperType | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const totalSlides = tab_content?.tabs?.length || 0;

  useEffect(() => {
    // Slide the active tab into view whenever activeTab changes
    if (swiperRef.current) {
      swiperRef.current.slideTo(activeTab, 500); // 500ms animation duration
    }
  }, [activeTab]);

  return (
    <>
      {totalSlides > 0 && (
        <div className={styles.tabContainer}>
          {/* Tab Navigation */}
          <Swiper
            modules={[Scrollbar, A11y]}
            simulateTouch={false}
            allowTouchMove={false}
            slidesPerView={"auto"}
            spaceBetween={0}
            className={`${styles.swiper} tab-content-swiper`}
            centeredSlides
            centeredSlidesBounds
            loop={false}
            breakpoints={{
              0: {
                simulateTouch: true,
                allowTouchMove: true
              },
              640: {
                simulateTouch: false,
                allowTouchMove: false
              }
            }}
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
          >
            {tab_content.tabs.map((tab, index) => (
              <SwiperSlide
                key={index}
                className={`${styles.swiperSlide} ${activeTab === index ? styles.activeTab : ""}`}
              >
                <div
                  onClick={() => handleTabClick(index)}
                  className={`${styles.tab} ${activeTab === index ? styles.tabSelected : ""}`}
                  ref={(el) => (contentRefs.current[index] = el)}
                >
                  {tab.tab_title}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Tab Content */}
          {tab_content.tabs.map((tab, index) => (
            <div
              key={index}
              className={`${styles.tabContent} ${activeTab === index ? styles.activeContent : styles.hiddenContent}`}
            >
              <div className={styles.tabContentLeft}>
                <span className={styles.title}>{tab.title}</span>
                <span className={styles.titleAlt}>{tab.title_alt}</span>
                <span className={styles.summary}>{tab.description}</span>
              </div>
                  <div className={styles.content}>{parse(DOMPurify.sanitize(tab.content))}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
