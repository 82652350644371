import React, { useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Layout from "./components/layout";
import Page from "./pages/index";
import LocationsOverview from "./pages/locations-overview";
import About from "./pages/about";
import Location from "./pages/location";
import Event from "./pages/event";
import Video from "./pages/video";
import Article from "./pages/article";
import WhitePaper from "./pages/white-paper";
import Webinar from "./pages/webinar";
import Error from "./pages/error";
import PageSelector from "./pages/page-selector"
import "./styles/third-party.css";
import "./styles/style.css";
import "./styles/modal.css";
import "@contentstack/live-preview-utils/dist/main.css";
import "react-loading-skeleton/dist/skeleton.css";
import { EntryProps } from "./typescript/components";
import ResourcesOverview from "./pages/resources-overview";
import MetalSelector from "./pages/metal-selector";
import CaseStudy from "./pages/case-study";
import Contact from "./pages/contact";
import { getCertificationsRes, getLocationRes, getResourcesRes } from "./helper";
import Certifications from "./pages/certifications-overview";
import Search from "./pages/search";
import { useTranslation } from "react-i18next";
import { useLocaleRedirect } from "./hooks/useLocalRedirect";

function RedirectEnToEnUs() {
    const location = useLocation();
    const newPath = location.pathname.replace(/^\/en\//, '/en-us/');
    return <Navigate to={newPath} replace />;
}

function App() {
    const [getEntry, setEntry] = useState({} as EntryProps);
    const { i18n } = useTranslation();
    const location = useLocation();

    useLocaleRedirect();
    
    // Function to update entry state
    function getPageResHandler(response: EntryProps) {
        setEntry(response);
    }
    
    return (
        <div className="App">
            <Routes>
                <Route path="/en/*" element={<RedirectEnToEnUs />} />

                {/* Redirect the root path to the current locale */}
                <Route path="/" element={<Navigate to={`/${i18n.language}`} replace />} />

                {/* Locale-based routes */}
                <Route path="/:lang/*" element={<Layout entry={getEntry} key={location.pathname} />}>
                    <Route index element={<Page entry={getPageResHandler} key={location.pathname} />} />
                    
                    <Route path="locations" element={<LocationsOverview key={location.pathname} entry={getLocationRes} />} />
                    <Route path="resources" element={<ResourcesOverview key={location.pathname} entry={getResourcesRes} />} />
                    <Route path="certifications" element={<Certifications key={location.pathname} entry={getCertificationsRes} />} />
                    <Route path="products/:productFamily/:productId" element={<PageSelector key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="products/:productId" element={<PageSelector key={location.pathname} entry={getPageResHandler} />} />

                    {/* Other Routes */}
                    <Route path="about" element={<About key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="about-us" element={<About key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="careers" element={<About key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="about-us/:tier1?/:tier2?" element={<About key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="contact-us" element={<Contact key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="search" element={<Search />} />

                    {/* Resource Routes */}
                    <Route path="resources/webinar/:webinarId" element={<Webinar key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="resources/article/:articleId" element={<Article key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="resources/white-paper/:whitePaperId" element={<WhitePaper key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="resources/case-study/:caseStudyId" element={<CaseStudy key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="resources/event/:eventId" element={<Event key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="resources/video/:videoId" element={<Video key={location.pathname} entry={getPageResHandler} />} />

                    <Route path="webinar/:webinarId" element={<Webinar key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="article/:articleId" element={<Article key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="white-paper/:whitePaperId" element={<WhitePaper key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="case-study/:caseStudyId" element={<CaseStudy key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="event/:eventId" element={<Event key={location.pathname} entry={getPageResHandler} />} />
                    <Route path="video/:videoId" element={<Video key={location.pathname} entry={getPageResHandler} />} />

                    <Route path="locations/:locationId" element={<Location key={location.pathname} entry={getPageResHandler} />} />
                    

                    <Route path="materials/metal-selector-tool" element={<MetalSelector key={location.pathname} url="/materials/metal-selector-tool" />} />
                    <Route path="metals/metal-selector-tool" element={<MetalSelector key={location.pathname} url="/metals/metal-selector-tool" />} />

                    {/* Dynamic route moved to the bottom to prevent conflicts */}
                    <Route path=":tier1/:tier2?/:tier3?/:tier4?" element={<Page key={location.pathname} entry={getPageResHandler} />} />

                    {/* Catch-all route for invalid paths within language scope */}
                    <Route path="404" element={<Error key={location.pathname} />} />
                    <Route path="*" element={<Navigate to={`/${i18n.language}/404`} replace />} />
                </Route>

                {/* Fallback to 404 if language is invalid */}
                <Route path="*" element={<Navigate to={`/${i18n.language}/404`} replace />} />
            </Routes>
        </div>
    );
}

export default App;