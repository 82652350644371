import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "../styles/SmallCardGrid.module.css";
import {
  SmallCard as SmallCardProp,
  SmallCardGrid as SmallCardGridProp,
} from "../typescript/components";
import SmallCard from "./small-card";

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { handleSwiperBreakpoint } from "../sdk/utils";
import AsideFilter from "./aside-filter";
import { TaxonomyRes } from "../typescript/pages";
import { getTaxonomyRes } from "../helper";
import { generateRandomId } from "../helper/utils";

const chunkCards = (cards: SmallCardProp[], size: number) => {
  const result = [];
  for (let i = 0; i < cards.length; i += size) {
    result.push(cards.slice(i, i + size));
  }
  return result;
};

export default function SmallCardGrid({
  small_card_grid,
}: {
  small_card_grid: SmallCardGridProp;
}) {
  const totalSlides = small_card_grid.small_cards.length;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState<number>(4);
  const shouldShowNavigation = currentSlidesPerView < totalSlides && totalSlides > 1;
  const [paginationId] = useState(generateRandomId());
  const [clientWidth, setClientWidth] = useState(document.documentElement.clientWidth);
  const hasFilter = small_card_grid?.filter?.show_filter ? styles.hasFilter : "";
  const [filters, setFilters] = useState({
    technologyFilter: [] as TaxonomyRes[],
    serviceFilter: [] as TaxonomyRes[],
    metalFilter: [] as TaxonomyRes[],
    industryFilter: [] as TaxonomyRes[],
    finishesFilter: [] as TaxonomyRes[],
  });

  const [getCards, setCards] = useState<SmallCardProp[]>(small_card_grid.small_cards);
  const [filteredCards, setFilteredCards] = useState<SmallCardProp[]>(small_card_grid.small_cards);

  const fetchData = useCallback(async () => {
    try {
      const requiredTaxonomies = small_card_grid.filter?.taxonomy || [];
      const getBlankTaxonomy = (name: string) => ({ name, items: [] });

      const taxonomyMap: Record<string, () => Promise<any>> = {
        technology: () => getTaxonomyRes("technology"),
        service: () => getTaxonomyRes("service"),
        metals: () => getTaxonomyRes("metals"),
        industry: () => getTaxonomyRes("industry"),
        finishes: () => getTaxonomyRes("finishes"),
      };

      const taxonomyPromises = Object.keys(taxonomyMap).map((taxonomy) => 
        requiredTaxonomies.includes(taxonomy) ? taxonomyMap[taxonomy]() : Promise.resolve(getBlankTaxonomy(taxonomy))
      );

      const [technologyFilter, serviceFilter, metalFilter, industryFilter, finishesFilter] = await Promise.all(taxonomyPromises);

      setFilters({
        technologyFilter: technologyFilter || [],
        serviceFilter: serviceFilter || [],
        metalFilter: metalFilter || [],
        industryFilter: industryFilter || [],
        finishesFilter: finishesFilter || [],
      });
    } catch (error) {
      console.error(error);
    }
  }, [small_card_grid.filter?.taxonomy]);

  useEffect(() => {
    fetchData();
    setCards(small_card_grid.small_cards); // Set cards for the current language content
    setFilteredCards(small_card_grid.small_cards); // Sync filtered cards with initial cards
  }, [fetchData, small_card_grid.small_cards]);

  useEffect(() => {
    const handleResize = () => {
      setClientWidth(document.documentElement.clientWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (small_card_grid.show_carousel && swiperRef.current && swiperRef.current.params) {
      const { navigation, pagination } = swiperRef.current.params;
      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;
        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
      if (pagination) {
        pagination.el = paginationRef.current;
        swiperRef.current.pagination?.destroy();
        swiperRef.current.pagination?.init();
        swiperRef.current.pagination?.update();
      }
    }
  }, [shouldShowNavigation, paginationId]);

  const parseFilters = (filters: string): string[] => {
    return filters ? filters.split(",").map((filter) => filter.trim().toLowerCase()) : [];
  };

  const matchesSelectedFilters = (
    card: SmallCardProp,
    selectedFilters: {
      technology: string[];
      service: string[];
      metal: string[];
      industry: string[];
      finishes: string[];
    }
  ) => {
    return parseFilters(card.filters).some((taxonomy) => {
      return (
        selectedFilters.technology.includes(taxonomy) ||
        selectedFilters.service.includes(taxonomy) ||
        selectedFilters.metal.includes(taxonomy) ||
        selectedFilters.industry.includes(taxonomy) ||
        selectedFilters.finishes.includes(taxonomy)
      );
    });
  };

  const applyFilters = (selectedFilters: {
    technology: string[];
    service: string[];
    metal: string[];
    industry: string[];
    finishes: string[];
  }) => {
    if (
      Object.values(selectedFilters).every((filter) => filter.length === 0)
    ) {
      setFilteredCards(getCards); // Reset to all resources if no filters selected
    } else {
      const filtered = getCards.filter((card) => matchesSelectedFilters(card, selectedFilters));
      setFilteredCards(filtered); // Update the filtered resources list
    }
  };

  const filterOptions = {
    technology: filters.technologyFilter,
    services: filters.serviceFilter,
    metals: filters.metalFilter,
    industries: filters.industryFilter,
    finishes: filters.finishesFilter,
  };

  const renderCardPairs = () => {
    const cards = chunkCards(filteredCards, 2);
    return cards.map((cardPair, index) => (
      <div key={index} className={`${styles.cardPairWrapper} 
        ${(index === cards.length - 1 && cards.length % 2 === 1) ? styles.lastOddPair : ''}  
        ${clientWidth < 1439 ? styles.centerOddPair : ''}`}>
        {cardPair.map((card, idx) => (
          <SmallCard
            key={card.title + idx}
            small_card={card}
            remove_overlay={small_card_grid.remove_overlay || false}
          />
        ))}
            {cardPair.length === 1 && filteredCards.length > 3 && <div className={styles.emptyCard}></div>}
      </div>
    ));
  };

  return (
    <div className={styles.root}>
      {(small_card_grid.eyebrow || small_card_grid.title || small_card_grid.description) && (
        <section className={styles.bodyParagraphCardParent}>
          {small_card_grid.eyebrow && <div className={styles.eyebrow}>{small_card_grid.eyebrow}</div>}
          {small_card_grid.title && <div className={styles.title}>{small_card_grid.title}</div>}
          {small_card_grid.description && <div className={styles.spaceForAn}>{small_card_grid.description}</div>}
        </section>
      )}

      <div className={`${styles.contentWrapper} ${hasFilter}`}>
        {small_card_grid?.filter?.show_filter && (
          <div className={styles.moduleWrapper}>
                <AsideFilter
                  key="resourcesFilter"
                  filter={filterOptions}
                  onApplyFilters={applyFilters}
                />
          </div>
        )}
        {!small_card_grid.show_carousel ? (
          filteredCards.length > 0 && (
            <section className={styles.smallSquareCardGroup}>
              {renderCardPairs()}
            </section>
          )
        ) : (
          <div className={styles.swiperWrapper}>
            <Swiper
              wrapperClass="smallCardWrapper"
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
              onBreakpoint={(swiper: SwiperType) => handleSwiperBreakpoint(swiper, setCurrentSlidesPerView)}
              breakpoints={{
                0: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
                1280: { slidesPerView: 4 },
              }}
              loop={true}
              spaceBetween={1}
              navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
              pagination={{
                el: `#${paginationId}`,
                clickable: true,
                enabled: shouldShowNavigation,
                dynamicBullets: true,
              }}
              className={`${styles.customSwiper} small-card-grid`}
            >
              {getCards.map((item, index) => (
                <SwiperSlide key={index} className={styles.slide}>
                  <SmallCard
                    key={item.title + index}
                    small_card={item}
                    remove_overlay={small_card_grid.remove_overlay || false}
                    isMobileCentered={true}
                  />
                </SwiperSlide>
              ))}
              {shouldShowNavigation && (
                <div className={styles.navigationWrapper}>
                  <button ref={prevRef} className={styles.prevButton}>
                    <Chevron
                      direction="left"
                      color="var(--chevron-color, var(--charcoal-500))"
                      size={20}
                    />
                  </button>
                  <div ref={paginationRef} className={styles.pagination} id={paginationId}></div>
                  <button ref={nextRef} className={styles.nextButton}>
                    <Chevron
                      direction="right"
                      color="var(--chevron-color, var(--charcoal-500))"
                      size={20}
                    />
                  </button>
                </div>
              )}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}