import React, { useEffect } from "react";
import styles from "../styles/Modal.module.css";
import ReactDOM from "react-dom";

interface ModalProps {
  className?: string;
  content: JSX.Element;
  closeHandler: () => void;
}

const Modal: React.FC<ModalProps> = ({
  content: Content,
  closeHandler,
  className,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") closeHandler();
    };
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [closeHandler]);

  return ReactDOM.createPortal(
    <div
      className={styles.overlay}
      onClick={(e) => {
        e.stopPropagation();
        closeHandler();
      }}
    >
      <div
        className={`${styles.container} ${className ?? ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.closeButtonWrapper}>
          <img src="/closex.svg" alt="Close" onClick={closeHandler} />
        </div>
        <div className={styles.content}>{Content}</div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
