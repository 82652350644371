import React, { useEffect, useState } from "react";
import styles from "../styles/BlogCard.module.css";
import { ResourcesRes } from "../typescript/pages";
import { translate } from "../helper/translate";
import i18n from "../sdk/i18n";
export default function BlogCard({ blog_post, component_type }: {  blog_post: ResourcesRes;  component_type: string; }) {

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    
    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.language);
        };

        console.log(blog_post);

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

  const getBlogCardClass = (component_type: string) => {
    switch (component_type) {
      case "Resource Grid":
        return styles.resourceCarousel;
      case "Resource List":
        return styles.resourceList;
      case "Blog Grid":
        return styles.blogGrid;
      default:
        return styles.resourceCarousel;
    }
  };

  const splitDescription = (description: string) => {
    if (!description || description.length === 0) return "";

    const words = description.split(" ");
    const truncated = words
      .slice(0, 12)
      .join(" ")
      ?.replace(/<[^>]+>/g, "");

    // Add ellipses if the description has more than 10 words
    return words.length > 10 ? `${truncated}...` : truncated;
  };

  const getTitle = (blog_post_item: ResourcesRes) => {
    switch (component_type) {
        case "Resource Grid":
            if (
                (blog_post_item._content_type_uid === "page") &&
                blog_post_item.page_components?.[0]?.hero_banner?.banner_title
            ) {
                return blog_post_item.page_components[0].hero_banner.banner_title;
            }
            if (
                (blog_post_item._content_type_uid === "metal_selector_overview")
            ) {
                return blog_post_item.header_title;
            }
            return blog_post_item.title;
      case "Resource List":
      case "Blog Grid":
        if (
            (blog_post_item._content_type_uid === "page") &&
            blog_post_item.page_components?.[0]?.hero_banner?.banner_title
        ) {
            return blog_post_item.page_components[0].hero_banner.banner_title;
        }
        if (
            (blog_post_item._content_type_uid === "metal_selector_overview")
        ) {
            return blog_post_item.header_title;
        }
        switch (
          blog_post_item._content_type_uid ||
          blog_post_item.type?.toLowerCase()
        ) {
          case "webinar":
                return translate("webinar", currentLanguage);
            case "whitepaper":
                return translate("whitepaper", currentLanguage);
            case "article":
                return translate("article", currentLanguage);
          case "case_study":
            case "case study":
                return translate("case_study", currentLanguage);
            case "video":
                return translate("video", currentLanguage);
            case "news":
                return translate("news", currentLanguage);
            case "event":
                return translate("event", currentLanguage);
          default:
                return blog_post_item.title; // Fallback to the post's title
        }
      default:
        return blog_post_item.title;
    }
  };

  const getDescription = (blog_post_item: ResourcesRes) => {
      switch (component_type) {
        case "Resource Grid":
            if (
                blog_post_item._content_type_uid === "page" &&
                blog_post_item.description
            ) {
                return blog_post_item.description;
            }
            if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.header_description) {
            return blog_post_item.header_description;
            }

            if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.description) {
                return blog_post_item.description;
            }
            return splitDescription(blog_post_item.summary);
      case "Blog Grid":
          case "Resource List":
              if (
                  blog_post_item._content_type_uid === "page" &&
                  blog_post_item.description
              ) {
                  return blog_post_item.description;
              }
              if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.header_description) {
                  return blog_post_item.header_description;
              }

              if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.description) {
                  return blog_post_item.description;
              }
        return blog_post_item.title;
      default:
        return blog_post_item.title;
    }
  };

  const getLinkText = (blog_post_item: ResourcesRes) => {
    switch (
      blog_post_item._content_type_uid ||
      blog_post_item?.type?.toLowerCase()
    ) {
      case "webinar":
            return translate("view_webinar", currentLanguage ) + " >";
      case "whitepaper":
            return translate("view_whitepaper", currentLanguage) + " >";
      case "article":
            return translate("view_article", currentLanguage) + " >";
      case "case_study":
            return translate("view_case_study", currentLanguage) + " >";
      case "video":
            return translate("watch_video", currentLanguage) + " >";
      case "news":
            return translate("view_news", currentLanguage) + " >";
      case "event":
            return translate("view_event", currentLanguage) + " >";
      default:
            return translate("learn_more", currentLanguage) + " >";
    }
  };

  // Determine the featured image
  const featuredImage =
    blog_post._content_type_uid === "page" &&
    blog_post.page_components &&
    blog_post.page_components.length > 0
      ? blog_post.page_components[0]?.hero_banner?.banner_media
      : blog_post.featured_image;

    return (

        <a href={blog_post.url} className={styles.linkWrapper}>
    <div className={`${styles.blogCard} ${getBlogCardClass(component_type)}`}>
                {featuredImage ? (
                    <div className={styles.imageContainer}>
            <img
              className={styles.imageIcon}
              loading="lazy"
              alt={featuredImage?.description || ""}
              src={
                  featuredImage?.url ? featuredImage.url + "?width=296&height=185&auto=webp&fit=crop" : ""
              }
            />
            {component_type === "Resource Grid" && (
                <div className={styles.imageOverlay}></div>
            )}
            </div>
      ) : (
        //Needs to be rebuilt dynamically per brand.
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt={"Default Resource Image"}
          src={
            "https://azure-na-images.contentstack.com/v3/assets/bltc3d459c709c6e7a5/bltbe37e282ea60ffda/671b192df9470596c8b73a1f/Image_(2).png"
          }
        />
      )}

      <div className={styles.content}>
        <div className={styles.copy}>
          <span className={`${styles.title} line-clamp-2`}>{getTitle(blog_post)}</span>
          <p className={`${styles.description} line-clamp-4`}>{getDescription(blog_post)}</p>
        </div>
        <span className={styles.link}>
          {getLinkText(blog_post)}
        </span>
              </div>

            </div>
        </a>
  );
}
