import React from "react";
import styles from "../styles/CtaBanner.module.css";
import Skeleton from "react-loading-skeleton";
import Button from "./button";
import { CTABannerProps } from "../typescript/components";

export default function CTABanner({
  cta_banner,
}: {
  cta_banner: CTABannerProps;
}) {
  const { title, subtitle, button, background } = cta_banner;
  return (
    <div className={styles.wrapper} style={{
      backgroundImage: background && background.url &&  `url(${background.url})`,
      }}>
    {/* Overlay element */}
    <div className={styles.overlay}></div>
      <div className={styles.content}>
        <div className={styles.text}>
          {title ? (
            <h2 className={styles.title}>{title}</h2>
          ) : (
            <Skeleton width={100} />
          )}
          {subtitle ? (
            <p className={styles.subtitle}>{subtitle}</p>
          ) : (
            <Skeleton width={100} />
          )}
        </div>
        {button.link.title && (
          <Button
            text={button.link?.title}
            showIcon
            propWidth="250px"
            propBorderColor={`${button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
            propBackgroundColor={`${button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
            propHoverBackgroundColor="transparent"
            propTextColor="var(--white)"
            propHoverText={`${button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
            link={button.link?.href}
          />
        )}
      </div>
    </div>
  );
}
