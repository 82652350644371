import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getContactRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { EntryProps, PageTitleHeader } from "../typescript/components";
import { ContactPage } from "../typescript/pages";
import styles from "../styles/Contact.module.css";
import PageTitle from "../components/page-title";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Contact({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
  const [searchParams] = useSearchParams(); // Hook to get query params
  const { i18n } = useTranslation();
  // Check if form_type exists in the query string
  const formType = searchParams.get("form_type");

  const history = useNavigate();
  const [getEntries, setEntries] = useState({} as ContactPage);
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
      const result = await getContactRes("/contact-us");
      if (!result) setError(true);
      setEntries({ ...result });
      entry({ page: [result] });
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }
  const entryUrl = "/contact-us";
  useEffect(() => {
    fetchData();
    if (error) history(`/${i18n.language}/404`);
  }, [entryUrl, lpTs, error, i18n.language]);

  const pageTitleData = {
    title: getEntries?.hero_title,
    description: getEntries?.hero_description,
  } as PageTitleHeader;

  // Construct the iframe URL with the form_type query parameter if it exists
  const iframeUrl =
    formType && getEntries?.dynamic_form_url
          ? `${getEntries?.dynamic_form_url}?Form Type=${formType}&Language=${i18n.language}`
          : `${getEntries?.dynamic_form_url}?Form Type=Contact_Us&Language=${i18n.language}`

  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;

  return Object.keys(getEntries).length ? (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.contactWrapper}>
        <PageTitle
          key="contactPageTitle"
          page_title={pageTitleData}
        ></PageTitle>
        <div className={styles.line}></div>
        <div className={styles.innerWrapper}>
          <div className={styles.content}>
            <div className={styles.iframeWrapper}>
              <span className={styles.formTitle}>{getEntries?.form_title}</span>
              <span className={styles.formDescription}>
                {getEntries?.form_description}
              </span>

              {iframeUrl && (
                <iframe
                  src={iframeUrl}
                  title="Contact Form"
                  className={styles.iframe}
                />
              )}
            </div>
            <div className={styles.imageWrapper}>
              {getEntries?.image && (
                <img
                                  src={getEntries?.image.url + "?width=636"}
                  alt={getEntries?.image.description || "Contact Image"}
                  className={styles.image}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <RenderComponents
        pageComponents={getEntries?.page_components}
        contentTypeUid="page"
        entryUid={getEntries?.uid}
        locale={getEntries?.locale}
        taxonomies={getEntries?.taxonomies}
      />
    </>
  ) : (
    <Skeleton count={5} height={400} />
  );
}
