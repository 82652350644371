import React from "react";
import styles from "../styles/CertificationsBanner.module.css";
import Skeleton from "react-loading-skeleton";
import Button from "./button";
import { CertificationsBannerProps } from "../typescript/components";

export default function CertificationsBanner({
  certifications_banner,
}: {
  certifications_banner: CertificationsBannerProps;
}) {
  const { icon, eyebrow, title, button } = certifications_banner;
  return (
    <div className={styles.wrapper}>
      {icon && (
        <div className={styles.icon}>
          <img loading="lazy" alt={icon.description || "Certifications Banner Icon"} src={icon.url} />
        </div>
      )}
      {eyebrow ? (
        <p className={styles.eyebrow}>{eyebrow}</p>
      ) : (
        <Skeleton width={100} />
      )}
      {title ? (
        <h3 className={styles.title}>{title}</h3>
      ) : (
        <Skeleton width={100} />
      )}
      {button.text ? (
        <Button
          text={button.text ?? button.link?.title}
          showIcon
          propWidth="250px"
          propBorderColor={`${button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
          propBackgroundColor={`${button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
          propHoverBackgroundColor="transparent"
          propTextColor="var(--white)"
          propHoverText={`${button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
          link={button.link?.href}
        />
      ) : (
        <Skeleton width={100} />
      )}
    </div>
  );
}
