import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axios, { AxiosResponse } from "axios";
import { Locale } from "../typescript/response";
import { DEFAULT_LOCALE } from "../typescript/constants";

interface LocalesResponse {
  locales: Locale[];
}

const {
  REACT_APP_CONTENTSTACK_API_KEY,
  REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
  REACT_APP_CONTENTSTACK_DEFAULT_LOCALE,
} = process.env;

export let cachedLocales: Locale[];

export const getLocales = async (): Promise<Locale[]> => {
  if (cachedLocales) {
    return cachedLocales;
  }

  try {
    const url = "https://azure-na-api.contentstack.com/v3/locales";
    const response: AxiosResponse<LocalesResponse> = await axios.get(url, {
      headers: {
        api_key: REACT_APP_CONTENTSTACK_API_KEY,
        authorization: REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
        "Content-Type": "application/json",
      },
    });

    cachedLocales = response.data.locales.sort((a, b) => a.name.localeCompare(b.name));
    return cachedLocales;
  } catch (error) {
    console.error("Error fetching locales:", error);
    return [];
  }
};

const initI18n = async () => {
  try {
    const locales = await getLocales();

    // Check if running in a browser environment before using `location`
    const urlLocale =
      typeof window !== "undefined"
        ? window.location.pathname.split("/")[1]
        : REACT_APP_CONTENTSTACK_DEFAULT_LOCALE;

    const pathSegments = urlLocale?.split("/").filter(Boolean);
    const firstSegment = pathSegments ? pathSegments[0] : undefined;
    const isValidLocale = firstSegment ? /^[a-z]{2}-[a-z]{2}$/.test(firstSegment) : false;
    const defaultLocale = isValidLocale
      ? urlLocale
      : REACT_APP_CONTENTSTACK_DEFAULT_LOCALE || DEFAULT_LOCALE;

    const resources = locales.reduce(
      (acc: { [key: string]: { translation: object } }, locale: Locale) => {
        acc[locale.code] = {
          translation: {}, // Load your translations here
        };
        return acc;
      },
      {}
    );

    const fallbackLng = locales.reduce(
      (acc: { [key: string]: string }, locale: Locale) => {
        acc[locale.code] = locale.fallback_locale || DEFAULT_LOCALE;
        return acc;
      },
      {}
    );

    await i18n.use(initReactI18next).init({
      resources,
      lng: defaultLocale,
      fallbackLng: fallbackLng[`${defaultLocale}`] || DEFAULT_LOCALE,
      interpolation: {
        escapeValue: false,
      },
    });
  } catch (error) {
    console.error("Error initializing i18n:", error);
  }
};

// Initialize i18n once on load
initI18n();

export default i18n;