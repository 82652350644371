import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getLocationDetail } from "../helper";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { LocationRes } from "../typescript/pages";
import styles from "../styles/Location.module.css";
import { EntryProps, PageTitleHeader } from "../typescript/components";
import PageTitle from "../components/page-title";
import FullWidthMedia from "../components/full-width-media";
import { FullWidthMedia as FullWidthMediaProp } from "../typescript/components";
import Button from "../components/button";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';
import WistiaVideo from "../components/wistia-video";
import { useTranslation } from "react-i18next";

export default function Product({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const { i18n } = useTranslation();
  const lpTs = useLivePreviewCtx();
  const { locationId } = useParams();
  const [getEntry, setEntry] = useState({
    location: {} as LocationRes,
  });
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const toProperCase = (str: string) =>
    str
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

  async function fetchData() {
    try {
      const entryUrl = locationId ? `/locations/${locationId}` : "/";
      const location = await getLocationDetail(entryUrl);
      if (!location) setError(true);
      setEntry({ location });
      entry({ location: [location] });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData();
    //error && history("/404");
  }, [locationId, lpTs, error, i18n.languages]);

  useEffect(() => {
    if (error) navigate(`/${i18n.language}/404`);
  }, [error])

  const { location } = getEntry;
  if (!location) return <></>;
  const pageTitleData = {
    title: location.header_title,
    description: location.header_description,
  } as PageTitleHeader;

  const fullWidthMedia = {
    media: location.map_media,
  } as FullWidthMediaProp;

  console.log(location);
  const meta_title = location?.seo?.meta_title;
  const meta_description = location?.seo?.meta_description;
    const keywords = location?.seo?.keywords;
    const formattedLocation = `${location.city || ''}${location.city && location.state ? ', ' : ''}${location.state || ''} ${location.postal_code || ''}`.trim();



  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className={styles.outerWrapper}>
        <PageTitle
          key="contactPageTitle"
          page_title={pageTitleData}
        ></PageTitle>
        <div className={styles.moduleWrapper}>
          <section className={styles.mediaWrapper}>
            <FullWidthMedia
              key="fullWidthMedia"
              full_width_media={fullWidthMedia}
            ></FullWidthMedia>
          </section>
          <section className={styles.contactWrapper}>
            <div className={styles.leftPanel}>
              <address>
                    <div className={styles.boldText}>Address</div>
                    {location.address && <p className={styles.normalText}>{location.address}</p>}
                    {location.address_2 && <p className={styles.normalText}>{location.address_2}</p>}
                    <p className={styles.normalText}>
                        {formattedLocation}
                    </p>
              </address>

              <div className={styles.contactInfo}>
                {location.phone && (
                    <>
                        <p className={styles.largeText}>Phone</p>
                        <p className={styles.normalText}>
                            <a href={`tel:${location.phone}`}>{location.phone}</a>
                        </p>
                    </>
                )}

                {location.sales && (
                  <>
                                  <p className={styles.largeText}>Sales</p>
                  <p className={styles.normalText}>
                    <a href={`tel:${location.sales}`}>{location.sales}</a>
                                      </p>
                                  </>
                )}
              </div>
            </div>
            <div className={styles.rightPanel}>
              {location.address && (
                <Button
                  text="Get Directions"
                  showIcon
                  propWidth="12.25rem"
                  propHeight="2.75rem"
                  propBackgroundColor={`var(--brand-primary-500)`}
                  propHoverBackgroundColor="transparent"
                  propTextColor="var(--white)"
                  link={location.address}
                />
              )}
            </div>
          </section>
          <div className={styles.root}>
            <section className={styles.virtualTour}>
              {location.media && (
                <img
                  src={location.media.url}
                  alt={location.media.description}
                  className={styles.image} // Use this class to control the image size and styling
                />
            )}

            {location.content && (
                <div className={styles.description}>
                                    {parse(DOMPurify.sanitize(location.content))}
                </div>
            )}
            <div className={styles.wistia}>
              {location.wistia && (
                <WistiaVideo embedUrl={location.wistia} responsive={true} />
              )} 
            </div>
            </section>
            <div className={styles.divider} />
            <div className={styles.taxonomyWrapper}>
              {location.taxonomies &&
                location.taxonomies.some(
                  (taxonomy) => taxonomy.taxonomy_uid === "certifications"
                ) && (
                  <>
                    <p className={styles.taxonomy}>Certifications:</p>
                    {location.taxonomies
                      .filter(
                        (taxonomy) => taxonomy.taxonomy_uid === "certifications"
                      )
                      .map((certification, index) => (
                        <p key={index} className={styles.taxonomyItem}>
                          {certification.term_uid
                            .replace(/_/g, " ")
                            .toUpperCase()}
                        </p>
                      ))}
                  </>
                )}
              {location.taxonomies &&
                location.taxonomies.some(
                  (taxonomy) => taxonomy.taxonomy_uid === "metals"
                ) && (
                  <>
                    <p className={styles.taxonomy}>Metals:</p>
                    {location.taxonomies
                      .filter((taxonomy) => taxonomy.taxonomy_uid === "metals")
                      .map((metal, index) => (
                        <p key={index} className={styles.taxonomyItem}>
                          {toProperCase(metal.term_uid.replace(/_/g, " "))}
                        </p>
                      ))}
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
