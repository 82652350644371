import React, { useState, useEffect } from "react";
import styles from "../styles/SearchBar.module.css";
import Button from "./button";

interface SearchBarProps {
  search_page: string;
  search_placeholder?: string;
  isVisible?: boolean; // Optional prop to control visibility from parent component
  toggleVisibility?: () => void; // Optional prop to handle visibility toggle from parent component
}

export default function SearchBar({
  search_page,
  search_placeholder = "Search by technology, metals, industries, or any keyword",
  isVisible = false,
  toggleVisibility,
}: SearchBarProps) {
  const [internalVisible, setInternalVisible] = useState(isVisible);
  const [searchValue, setSearchValue] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen size is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set breakpoint for mobile
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setInternalVisible(isVisible);
  }, [isVisible]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getSearchUrl = () => {
    return `${search_page}?s=${encodeURIComponent(searchValue)}`;
  };

  const handleToggleVisibility = () => {
    if (toggleVisibility) {
      toggleVisibility(); // Use external toggle if provided
    } else {
      setInternalVisible(!internalVisible); // Otherwise, use internal state
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      window.location.href = getSearchUrl();
    }
  };

  const visible = toggleVisibility ? isVisible : internalVisible; // Determine which state to use

  return (
    <div className={styles.searchWrapper}>
      <img
        className={styles.searchIcon}
        loading="lazy"
        alt="Search"
        // Change the icon based on visibility and mobile state
        src={isMobile && visible ? "/closex.svg" : "/search.svg"}
        onClick={handleToggleVisibility}
      />
      {/* Desktop search bar */}
      <div
        className={`${styles.searchBar} ${!isMobile && visible ? styles.searchBarVisible : ""}`}
      >
        <div className={styles.container}>
          <div className={styles.actions}>
            <span className={styles.search}>Search</span>
            <div className={styles.close} onClick={handleToggleVisibility}>
              <span className={styles.close1}>Close</span>
              <img
                className={styles.closeIcon}
                loading="lazy"
                alt="Close"
                src="/closex.svg"
              />
            </div>
          </div>
          <div className={styles.searchInputBar}>
            <input
              className={styles.searchInput}
              placeholder={search_placeholder}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className={styles.searchButton}>
              <Button
                text="Search"
                link={getSearchUrl()}
                propHeight="28px"
                propWidth="98px"
                propBackgroundColor="var(--primary-500)"
                propBorderColor="var(--primary-500)"
                propTextColor="white"
                propHoverText="white"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile overlay */}
      <div
        className={`${styles.mobileOverlay} ${isMobile && visible ? styles.searchOverlayVisible : ""}`}
        style={{
          transition: "max-height 0.5s ease",
          maxHeight: visible ? "100%" : "0px",
          overflow: "hidden",
        }}
      >
        <div className={styles.mobileContainer}>
          <span className={styles.searchTitleMobile}>Search</span>
          <div className={styles.searchInputBarMobile}>
            <input
              className={styles.searchInputMobile}
              placeholder={search_placeholder}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className={styles.searchButtonMobile}>
              <Button
                text="Search"
                link={getSearchUrl()}
                propHeight="2.625rem"
                propWidth="7.5625rem"
                propBackgroundColor="var(--primary-500)"
                propBorderColor="var(--primary-500)"
                propTextColor="white"
                propHoverText="white"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
