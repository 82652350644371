import React, { useEffect, useState } from "react";
import styles from "../styles/RichText.module.css";
import "swiper/css";
import "swiper/css/navigation";
import { RichText as RichTextProps } from "../typescript/components";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';
import { useSearchParams } from "react-router-dom";

export default function RichText({ richText }: { richText: RichTextProps }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [searchParams] = useSearchParams(); // Hook to get query params

  useEffect(() => {
    // Check if the richText content contains the tagembed URL
      if (richText.general_embed && richText.general_embed.includes("widget.tagembed.com")) {
      const script = document.createElement("script");
      script.src = "https://widget.tagembed.com/embed.min.js";
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
        setContentLoaded(true);
      };
      script.onerror = () => console.error("Script loading failed!");
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [richText.general_embed]);

  const iframeUrl = richText?.dynamic_form_url;

  return (
      <div className={styles.wrapper}>
          {richText.content && (
              <div className={styles.container}>{parse(DOMPurify.sanitize(richText.content))}</div>
          )}
      {iframeUrl && iframeUrl.trim() !== "" && (
        <>
          <div className={styles.divider}></div>
          <iframe
            src={iframeUrl}
            title="Contact Form"
            className={styles.iframe}
          />
        </>
          )}
          {richText.general_embed && (
              <div className={styles.container}>{parse(DOMPurify.sanitize(richText.general_embed))}</div>
           )}

    </div>
  );
}
