import React, { useEffect } from "react";

import Section from "./section";
import HeroBanner from "./hero-banner";
import BlogBanner from "./blog-banner";
import AboutBanner from "./about-banner";
import MapBanner from "./map-banner";
import ResourceGrid from "./resource-grid";
import LogoBanner from "./logo-banner";
import CardSection from "./card-section";
import TeamSection from "./team-section";
import BlogSection from "./blog-section";
import VerticalCarousel from "./vertical-carousel";
import VerticalCard from "./vertical-card";
import SectionBucket from "./section-bucket";
import AboutSectionBucket from "./about-section-bucket";
import SectionWithHtmlCode from "./section-with-html-code";
import { ComponentsProps } from "../typescript/pages";
import ProductDisplayBanner from "./product-display-banner";
import ServicesTab from "./services-tab";
import CTABanner from "./cta-banner";
import LargeCardGrid from "./large-card-grid";
import LargeCard from "./large-card";
import SmallCardGrid from "./small-card-grid";
import SmallCard from "./small-card";
import EventSpotlightBanner from "./event-spotlight-banner";
import CTAOverlayBanner from "./cta-overlay-banner";
import CertificationsBanner from "./certifications-banner";
import HeroTitle from "./hero-title";
import FullWidthMedia from "./full-width-media";
import CompanyCareer from "./company-career";
import Testimonial from "./testimonial";
import CompanySpotlight from "./company-spotlight";
import ResourceAside from "./resource-aside";
import Timeline from "./timeline";
import SimpleCard from "./simple-card";
import TabBar from "./tab-bar";
import TabContent from "./tab-content";
import RichText from "./rich-text";

type RenderComponentsProps = {
  pageComponents: ComponentsProps[];
  blogsPage?: boolean;
  contentTypeUid: string;
  entryUid: string;
  locale: string;
  taxonomies: [
    {
      taxonomy_uid: string;
      term_uid: string;
    },
  ];
};

export default function RenderComponents({
  pageComponents,
  blogsPage,
  contentTypeUid,
  entryUid,
  locale,
  taxonomies,

}: RenderComponentsProps) {
  return (
    <div
      data-pageref={entryUid}
      data-contenttype={contentTypeUid}
      data-locale={locale}
    >
      {pageComponents?.map((component, key: number) => {
        if (component.hero_banner) {
          return blogsPage ? (
            <BlogBanner
              blog_banner={component.hero_banner}
              key={`component-${key}`}
            />
          ) : (
            <HeroBanner
              hero_banner={component.hero_banner}
              key={`component-${key}`}
            />
          );
        }
        if (component.hero_title) {
          return (
            <HeroTitle
              key={`component-${key}`}
              hero_title={component.hero_title}
            />
          );
        }
        if (component.section) {
          return (
            <Section section={component.section} key={`component-${key}`} />
          );
        }
        if (component.section_with_buckets) {
          return component.section_with_buckets.bucket_tabular ? (
            <AboutSectionBucket
              sectionWithBuckets={component.section_with_buckets}
              key={`component-${key}`}
            />
          ) : (
            <SectionBucket
              section={component.section_with_buckets}
              key={`component-${key}`}
            />
          );
        }
        if (component.from_blog) {
          return (
            <BlogSection blogs={component.from_blog} key={`component-${key}`} />
          );
        }
        if (component.section_with_cards) {
          return (
            <CardSection
              cards={component.section_with_cards.cards}
              key={`component-${key}`}
            />
          );
        }
        if (component.rich_text) {
          return (
            <RichText richText={component.rich_text} key={`component-${key}`} />
          );
        }
        if (component.section_with_html_code) {
          return (
            <SectionWithHtmlCode
              embedObject={component.section_with_html_code}
              key={`component-${key}`}
            />
          );
        }
        if (component.our_team) {
          return (
            <TeamSection
              ourTeam={component.our_team}
              key={`component-${key}`}
            />
          );
        }
        if (component.logo_banner) {
          return (
            <LogoBanner
              logoBanner={component.logo_banner}
              key={`component-${key}`}
            />
          );
        }
        if (component.about_banner) {
          return (
            <AboutBanner
              about_banner={component.about_banner}
              key={`component-${key}`}
            />
          );
        }

        if (component.vertical_carousel) {
          return (
            <VerticalCarousel
              vertical_carousel={component.vertical_carousel}
              key={`component-${key}`}
            />
          );
        }

        if (component.vertical_card) {
          return (
            <VerticalCard
              vertical_card={component.vertical_card}
              key={`component-${key}`}
            />
          );
        }
        if (component.cta_banner) {
          return (
            <CTABanner
              cta_banner={component.cta_banner}
              key={`component-${key}`}
            />
          );
        }

        if (component.event_spotlight_banner) {
          return (
            <EventSpotlightBanner
              event_spotlight_banner={component.event_spotlight_banner}
              key={`component-${key}`}
            />
          );
        }

        if (component.certifications_banner) {
          return (
            <CertificationsBanner
              certifications_banner={component.certifications_banner}
              key={`component-${key}`}
            />
          );
        }

        if (component.tab_bar) {
          return (
            <TabBar tab_bar={component.tab_bar} key={`component-${key}`} />
          );
        }

        if (component.cta_overlay_banner) {
          return (
            <CTAOverlayBanner
              cta_overlay_banner={component.cta_overlay_banner}
              key={`component-${key}`}
            />
          );
        }
        if (component.map_banner) {
          return (
            <MapBanner
              map_banner={component.map_banner}
              key={`component-${key}`}
            />
          );
        }
        if (component.product_display_banner) {
          return (
            <ProductDisplayBanner
              product_display_banner={component.product_display_banner}
              key={`component-${key}`}
            />
          );
        }

        if (component.services_tab) {
          return (
            <ServicesTab
              services_tab={component.services_tab}
              key={`component-${key}`}
            />
          );
        }

        if (component.large_card_grid) {
          return (
            <LargeCardGrid
              large_card_grid={component.large_card_grid}
              key={`component-${key}`}
            />
          );
        }

        if (component.large_card) {
          return (
            <LargeCard
              large_card={component.large_card}
              key={`component-${key}`}
            />
          );
        }

        if (component.small_card_grid) {
          return (
            <SmallCardGrid
              small_card_grid={component.small_card_grid}
              key={`component-${key}`}
            />
          );
        }

        if (component.small_card) {
          return (
            <SmallCard
              small_card={component.small_card}
              key={`component-${key}`}
            />
          );
        }

        if (component.tab_content) {
          return (
            <TabContent
              tab_content={component.tab_content}
              key={`component-${key}`}
            />
          );
        }

        if (component.simple_card_grid) {
          return (
            <SimpleCard
              simple_card={component.simple_card_grid}
              key={`component-${key}`}
            />
          );
        }

        if (component.resource_grid) {
          return (
            <ResourceGrid
              resource_grid={component.resource_grid}
              key={`component-${key}`}
              taxonomies={taxonomies}
            />
          );
        }

        if (component.testimonial) {
          return (
            <Testimonial
              testimonial={component.testimonial}
              key={`component-${key}`}
            />
          );
        }

        if (component.full_width_media) {
          return (
            <FullWidthMedia
              full_width_media={component.full_width_media}
              key={`component-${key}`}
            />
          );
        }
        if (component.company_spotlight) {
          return (
            <CompanySpotlight
              company_spotlight={component.company_spotlight}
              key={`component-${key}`}
            />
          );
        }
        if (component.timeline) {
          return (
            <Timeline timeline={component.timeline} key={`component-${key}`} />
          );
        }

        if (component.company_career) {
          return (
            <CompanyCareer
              company_career={component.company_career}
              key={`component-${key}`}
            />
          );
        }
      })}
    </div>
  );
}
