import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/BlogSection.module.css";
import { FromBlog } from "../typescript/components";
import Button from "./button";

export default function BlogSection({ blogs: fromBlog }: { blogs: FromBlog }) {
  return (
    <div className={[styles.blogGrid].join(" ")}>
      <section className={styles.title}>
        {fromBlog.title_h2 && (
          <h2
            className={styles.dieCastingResources}
            {...(fromBlog.$?.title_h2 as {})}
          >
            {fromBlog.title_h2}
          </h2>
        )}
        {fromBlog.title_h2}
      </section>
      <section className={styles.cards}>
        {fromBlog.view_articles && (
          <Link
            to={fromBlog.view_articles.href}
            className="btn secondary-btn article-btn"
            {...(fromBlog.view_articles.$?.title as {})}
          >
            {fromBlog.view_articles.title}
          </Link>
        )}
      </section>
      <Button
        text="See All Resources"
        showIcon
        propWidth="250px"
        propBackgroundColor="var(--primary-500)"
        propHoverBackgroundColor="transparent"
        propTextColor="var(--white)"
        link="/"
      />
    </div>
  );
}
