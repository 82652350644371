type Translations = {
    [locale: string]: {
        [key: string]: string;
    };
};

const translations: Translations = {
    "en-us": {
        view_webinar: "View Webinar",
        view_whitepaper: "View Whitepaper",
        view_article: "View Article",
        view_case_study: "View Case Study",
        watch_video: "Watch Video",
        view_news: "View News",
        view_event: "View Event",
        webinar: "Webinar",
        whitepaper: "Whitepaper",
        article: "Article",
        case_study: "Case Study",
        video: "Video",
        news: "News",
        event: "Event",
        learn_more: "Learn More",
        filters: "Filters",
        clear_all_filters: "Clear All Filters",
        by_type: "By Type",
        locations: "Locations",
        certifications: "Certifications",
        metals: "Metals",
    },
    "en-gb": {
        view_webinar: "View Webinar",
        view_whitepaper: "View Whitepaper",
        view_article: "View Article",
        view_case_study: "View Case Study",
        watch_video: "Watch Video",
        view_news: "View News",
        view_event: "View Event",
        webinar: "Webinar",
        whitepaper: "Whitepaper",
        article: "Article",
        case_study: "Case Study",
        video: "Video",
        news: "News",
        event: "Event",
        learn_more: "Learn More",
        filters: "Filters",
        clear_all_filters: "Clear All Filters",
        by_type: "By Type",
        locations: "Locations",
        certifications: "Certifications",
        metals: "Metals",
    },
    "en-sg": {
        "view_webinar": "View Webinar",
        "view_whitepaper": "View Whitepaper",
        "view_article": "View Article",
        "view_case_study": "View Case Study",
        "watch_video": "Watch Video",
        "view_news": "View News",
        "view_event": "View Event",
        "webinar": "Webinar",
        "whitepaper": "Whitepaper",
        "article": "Article",
        "case_study": "Case Study",
        "video": "Video",
        "news": "News",
        "event": "Event",
        "learn_more": "Learn More",
        "filters": "Filters",
        "clear_all_filters": "Clear All Filters",
        "by_type": "By Type",
        "locations": "Locations",
        "certifications": "Certifications",
        "metals": "Metals"
    },
    "es-mx": {
        view_webinar: "Ver Seminario Web",
        view_whitepaper: "Ver Whitepaper",
        view_article: "Ver Artículo",
        view_case_study: "Ver Caso de Estudio",
        watch_video: "Ver Video",
        view_news: "Ver Noticias",
        view_event: "Ver Evento",
        webinar: "Seminario Web",
        whitepaper: "Whitepaper",
        article: "Artículo",
        case_study: "Caso de Estudio",
        video: "Video",
        news: "Noticias",
        event: "Evento",
        learn_more: "Aprende Más",
        filters: "Filtros",
        clear_all_filters: "Limpiar Todos Los Filtros",
        by_type: "Por Tipo",
        locations: "Ubicaciones",
        certifications: "Certificaciones",
        metals: "Metales",
    },
    "es-es": {
        welcome: "Bienvenido",
        goodbye: "Adiós",
        thank_you: "Gracias",
        view_webinar: "Ver Seminario Web",
        view_whitepaper: "Ver Whitepaper",
        view_article: "Ver Artículo",
        view_case_study: "Ver Caso de Estudio",
        watch_video: "Ver Video",
        view_news: "Ver Noticias",
        view_event: "Ver Evento",
        webinar: "Seminario Web",
        whitepaper: "Whitepaper",
        article: "Artículo",
        case_study: "Caso de Estudio",
        video: "Video",
        news: "Noticias",
        event: "Evento",
        learn_more: "Aprender Más",
        filters: "Filtros",
        clear_all_filters: "Limpiar Todos Los Filtros",
        by_type: "Por Tipo",
        locations: "Ubicaciones",
        certifications: "Certificaciones",
        metals: "Metales",
    },
    "fr-fr": {
        view_webinar: "Voir le Webinaire",
        view_whitepaper: "Voir le Livre Blanc",
        view_article: "Voir l'Article",
        view_case_study: "Voir l'Étude de Cas",
        watch_video: "Regarder la Vidéo",
        view_news: "Voir les Actualités",
        view_event: "Voir l'Événement",
        webinar: "Webinaire",
        whitepaper: "Livre Blanc",
        article: "Article",
        case_study: "Étude de Cas",
        video: "Vidéo",
        news: "Actualités",
        event: "Événement",
        learn_more: "En savoir plus",
        filters: "Filtres",
        clear_all_filters: "Effacer Tous Les Filtres",
        by_type: "Par Type",
        locations: "Lieux",
        certifications: "Certifications",
        metals: "Métaux",
    },
    "de-de": {
        "view_webinar": "Webinar ansehen",
        "view_whitepaper": "Whitepaper ansehen",
        "view_article": "Artikel ansehen",
        "view_case_study": "Fallstudie ansehen",
        "watch_video": "Video ansehen",
        "view_news": "Nachrichten ansehen",
        "view_event": "Veranstaltung ansehen",
        "webinar": "Webinar",
        "whitepaper": "Whitepaper",
        "article": "Artikel",
        "case_study": "Fallstudie",
        "video": "Video",
        "news": "Nachrichten",
        "event": "Veranstaltung",
        "learn_more": "Mehr erfahren",
        "filters": "Filter",
        "clear_all_filters": "Alle Filter Löschen",
        "by_type": "Nach Typ",
        "locations": "Standorte",
        "certifications": "Zertifizierungen",
        "metals": "Metalle"
    },
    "hi-in": {
        "view_webinar": "वेबिनार देखें",
        "view_whitepaper": "श्वेतपत्र देखें",
        "view_article": "लेख देखें",
        "view_case_study": "मामले का अध्ययन देखें",
        "watch_video": "वीडियो देखें",
        "view_news": "समाचार देखें",
        "view_event": "इवेंट देखें",
        "webinar": "वेबिनार",
        "whitepaper": "श्वेतपत्र",
        "article": "लेख",
        "case_study": "मामले का अध्ययन",
        "video": "वीडियो",
        "news": "समाचार",
        "event": "इवेंट",
        "learn_more": "अधिक जानें",
        "filters": "फ़िल्टर",
        "clear_all_filters": "सभी फ़िल्टर साफ़ करें",
        "by_type": "प्रकार के अनुसार",
        "locations": "स्थान",
        "certifications": "प्रमाणपत्र",
        "metals": "धातु"
    },
    "it-it": {
        "view_webinar": "Visualizza Webinar",
        "view_whitepaper": "Visualizza Whitepaper",
        "view_article": "Visualizza Articolo",
        "view_case_study": "Visualizza Caso di Studio",
        "watch_video": "Guarda Video",
        "view_news": "Visualizza Notizie",
        "view_event": "Visualizza Evento",
        "webinar": "Webinar",
        "whitepaper": "Whitepaper",
        "article": "Articolo",
        "case_study": "Caso di Studio",
        "video": "Video",
        "news": "Notizie",
        "event": "Evento",
        "learn_more": "Scopri di più",
        "filters": "Filtri",
        "clear_all_filters": "Cancella Tutti I filtri",
        "by_type": "Per Tipo",
        "locations": "Località",
        "certifications": "Certificazioni",
        "metals": "Metalli"
    },
    "ja-jp": {
        "view_webinar": "ウェビナーを見る",
        "view_whitepaper": "ホワイトペーパーを見る",
        "view_article": "記事を見る",
        "view_case_study": "ケーススタディを見る",
        "watch_video": "ビデオを見る",
        "view_news": "ニュースを見る",
        "view_event": "イベントを見る",
        "webinar": "ウェビナー",
        "whitepaper": "ホワイトペーパー",
        "article": "記事",
        "case_study": "ケーススタディ",
        "video": "ビデオ",
        "news": "ニュース",
        "event": "イベント",
        "learn_more": "もっと知る",
        "filters": "フィルター",
        "clear_all_filters": "すべてのフィルターをクリア",
        "by_type": "種類別",
        "locations": "場所",
        "certifications": "認証",
        "metals": "金属"
    },
    "ko-kr": {
        "view_webinar": "웨비나 보기",
        "view_whitepaper": "백서 보기",
        "view_article": "기사 보기",
        "view_case_study": "사례 연구 보기",
        "watch_video": "비디오 보기",
        "view_news": "뉴스 보기",
        "view_event": "이벤트 보기",
        "webinar": "웨비나",
        "whitepaper": "백서",
        "article": "기사",
        "case_study": "사례 연구",
        "video": "비디오",
        "news": "뉴스",
        "event": "이벤트",
        "learn_more": "더 알아보기",
        "filters": "필터",
        "clear_all_filters": "모든 필터 지우기",
        "by_type": "유형별",
        "locations": "위치",
        "certifications": "인증",
        "metals": "금속"
    },
    "zh-cn": {
        view_webinar: "查看网络研讨会",
        view_whitepaper: "查看白皮书",
        view_article: "查看文章",
        view_case_study: "查看案例研究",
        watch_video: "观看视频",
        view_news: "查看新闻",
        view_event: "查看活动",
        webinar: "网络研讨会",
        whitepaper: "白皮书",
        article: "文章",
        case_study: "案例研究",
        video: "视频",
        news: "新闻",
        event: "活动",
        learn_more: "了解更多",
        filters: "筛选器",
        clear_all_filters: "清除所有筛选器",
        by_type: "按类型",
        locations: "位置",
        certifications: "认证",
        metals: "金属",
    },
    "zh-tw": {
        view_webinar: "查看網絡研討會",
        view_whitepaper: "查看白皮書",
        view_article: "查看文章",
        view_case_study: "查看案例研究",
        watch_video: "觀看視頻",
        view_news: "查看新聞",
        view_event: "查看活動",
        webinar: "網絡研討會",
        whitepaper: "白皮書",
        article: "文章",
        case_study: "案例研究",
        video: "視頻",
        news: "新聞",
        event: "活動",
        learn_more: "了解更多",
        filters: "篩選器",
        clear_all_filters: "清除所有篩選器",
        by_type: "按類型",
        locations: "地點",
        certifications: "認證",
        metals: "金屬",
    },
};

export const translate = (
    key: string,
    locale: string,
    fallback: string = key
): string => {
    if (translations[locale] && translations[locale][key]) {
        return translations[locale][key];
    }
    return fallback; // Fallback to the provided key or a default value
};
