import React, { useState, useEffect } from "react";
import {
  getCertificationOverviewRes,
  getCertificationsRes,
  getTaxonomyRes,
} from "../helper";
import {
  CertificationsOverviewEntryProps,
  PageTitleHeader,
} from "../typescript/components";
import {
  CertificationsOverviewPage,
  CertificationsRes,
  TaxonomyRes,
} from "../typescript/pages"; // Ensure `Certification` is imported
import styles from "../styles/Certifications.module.css";
import Filter from "../components/filters";
import Button from "../components/button";
import PageTitle from "../components/page-title";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function CertificationsOverview({ entry }: { entry: ({ certifications_overview }: CertificationsOverviewEntryProps) => void;}) {
  const [getEntries, setEntries] = useState<CertificationsOverviewPage>({} as CertificationsOverviewPage);
  const { i18n } = useTranslation();
  const [error, setError] = useState(false);
  const [dataFetched, setDataFetched] = useState(false); // Ensure data is only fetched once
  const [filters, setFilters] = useState({
    locationsFilter: [] as TaxonomyRes[],
    metalsFilter: [] as TaxonomyRes[],
    certificationFilter: [] as TaxonomyRes[],
  });
  const navigate = useNavigate();
  const [getCertifications, setCertifications] = useState<CertificationsRes[]>(
    []
  );
  const [filteredCertifications, setFilteredCertifications] = useState<
    CertificationsRes[]
  >([]); // Add state for filtered certifications

  // Function to fetch page data
  async function fetchData() {
    try {
      const [
        result,
        certifications,
        locationFilter,
        certificationFilter,
        metalFilter,
      ] = await Promise.all([
        getCertificationOverviewRes("/certifications"),
        getCertificationsRes(),
        getTaxonomyRes("location"),
        getTaxonomyRes("certifications"),
        getTaxonomyRes("metals"),
      ]);

      setFilters({
        locationsFilter: locationFilter || [],
        metalsFilter: metalFilter || [],
        certificationFilter: certificationFilter || [],
      });

      setCertifications(certifications.flat() || []); // Ensure certifications is set as a flat array
      setFilteredCertifications(certifications.flat() || []); // Set the filtered list initially to match the full list

      if (!result) {
        setError(true);
      } else {
        setEntries(result);
        entry({ certifications_overview: [result] });
      }

      setDataFetched(true); // Mark that data has been fetched
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }
  useEffect(() => {
    if (error) navigate(`/${i18n.language}/404`);
  }, [error])
  
  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;

  // useEffect to fetch data only once after mount
  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // Only run useEffect once when data hasn't been fetched

  useEffect(() => {
    if (dataFetched) { // Only render when changing language after component mounted
      fetchData();
    }
  }, [i18n.language]);

  const filterOptions = {
    locations: filters.locationsFilter,
    certifications: filters.certificationFilter,
    metals: filters.metalsFilter,
  };

  const matchesSelectedFilters = (
    cert: CertificationsRes,
    selectedFilters: {
      locations: string[];
      certifications: string[];
      metals: string[];
    }
  ) => {
    return cert.taxonomies.some((taxonomy) => {
      if (
        selectedFilters.locations.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "location"
      ) {
        return true;
      }
      if (
        selectedFilters.certifications.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "certifications"
      ) {
        return true;
      }
      if (
        selectedFilters.metals.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "metals"
      ) {
        return true;
      }
      return false;
    });
  };

  // Function to filter certifications based on selected filters
  const applyFilters = (selectedFilters: {
    locations: string[];
    certifications: string[];
    metals: string[];
  }) => {
    if (
      selectedFilters.locations.length === 0 &&
      selectedFilters.certifications.length === 0 &&
      selectedFilters.metals.length === 0
    ) {
      // No filters selected, show the entire list
      setFilteredCertifications(getCertifications);
    } else {
      // Filters are selected, apply the filtering logic
      const filtered = getCertifications.filter((cert) => {
        return matchesSelectedFilters(cert, selectedFilters);
      });

      setFilteredCertifications(filtered); // Update the filtered certifications list
    }
  };

  const pageTitleData = { title: getEntries?.header_title, description: getEntries?.header_description } as PageTitleHeader;

  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (<meta name="description" content={meta_description} />)}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.outerWrapper}>
        <PageTitle
          key="contactPageTitle"
          page_title={pageTitleData}
        ></PageTitle>
        <div className={styles.moduleWrapper}>

              <Filter
                key="locationsFilter"
                filter={filterOptions}
                onApplyFilters={applyFilters}
              />
          <div className={styles.wrapper}>
            {filteredCertifications &&
              filteredCertifications.map((cert: CertificationsRes, index) => (
                <div className={styles.certification} key={cert.uid || index}>
                  <div className={styles.innerWrapper}>
                    <div className={styles.name}>
                      <img
                        className={styles.icon}
                        alt={cert.icon?.description || "Certification Icon"}
                        src={
                          cert.icon?.url ||
                          "https://azure-na-images.contentstack.com/v3/assets/bltc3d459c709c6e7a5/blt9d545255a9015ec5/66d8109f192d6a56a4fbef26/Certification.svg"
                        }
                      />
                      {cert.title && <h3>{cert.title}</h3>}
                    </div>
                    {cert.description && (
                      <span
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: cert.description,
                        }}
                      />
                    )}
                    <div className={styles.bottom}>
                      <p>{cert.note && cert.note}</p>
                      {cert.button?.text && (
                        <Button
                          text={cert.button.text ?? cert.button.text}
                          propWidth="147px"
                          propHeight="27px"
                          showIcon={false}
                          propBorderColor={`var(--brand-primary-500)`}
                          propBackgroundColor={`var(--brand-primary-500)`}
                          propHoverBackgroundColor="transparent"
                          propTextColor="var(--white)"
                          propHoverText={`var(--brand-primary-500)`}
                          link={cert.button.link?.href}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
