import React from "react";
import styles from "../styles/BioDetail.module.css";
import { VerticalCard as VerticalCardProp } from "../typescript/components";

const BioDetail: React.FC<{ data: VerticalCardProp }> = ({ data }) => {
  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h3 className={styles.title}>{data.title}</h3>
        <h5 className={styles.jobTitle}>{data.slogan}</h5>
        <img
          className={styles.imageMobile}
          alt={data.background_image.description || "Background Image"}
          src={appendWidthParam(data.background_image.url, 248)}
        />
        <p className={styles.description}>{data.description}</p>
      </div>
      <img
        className={styles.image}
        alt={data.background_image.description || "Background Image"}
        src={appendWidthParam(data.background_image.url, 248)}
      />
    </div>
  );
};

export default BioDetail;
