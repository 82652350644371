import React, { useState, useEffect } from "react";
import styles from "../styles/ProductDisplayBanner.module.css";
import {
  ProductDisplayBanner as ProductDisplayBannerProp,
  ProductMedia,
} from "../typescript/components";
import Button from "./button";
import Modal from "./modal";
import { generateVideoThumbnail, generateWistiaThumbnail } from "../sdk/utils";
import Skeleton from "react-loading-skeleton";
import WistiaVideo from "./wistia-video";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';

const PlayBtnSVG = () => {
  return (
    <svg
      width="107"
      height="107"
      viewBox="0 0 107 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#240;&#159;&#166;&#134; icon &#34;music play button&#34;">
        <g id="Group">
          <path
            id="Vector"
            d="M45.0156 35.4121L68.4412 53.7888L45.0156 72.1654V35.4121Z"
            stroke="var(--primary-500)"
            strokeWidth="2.54163"
            strokeMiterlimit="10"
            strokeLinejoin="bevel"
          />
          <path
            id="Vector_2"
            d="M90.0601 17.1708C110.318 37.3934 110.318 70.1824 90.0601 90.4067C69.8037 110.634 36.961 110.634 16.7063 90.4067C-3.55183 70.1841 -3.55183 37.3951 16.7063 17.1708C36.961 -3.05692 69.802 -3.05692 90.0601 17.1708V17.1708Z"
            stroke="var(--primary-500)"
            strokeWidth="2.54163"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  );
};

export default function ProductDisplayBanner({
  product_display_banner,
}: {
  product_display_banner: ProductDisplayBannerProp;
}) {
  const mediaAssets = product_display_banner.media || []; // Ensure mediaAssets is not null/undefined
  const [selectedMedia, setSelectedMedia] = useState<ProductMedia | null>(
    mediaAssets[0] || null
  );
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const closeModal = () => setIsModalOpen(false);

  // Generate thumbnails for video assets when component mounts
  useEffect(() => {
    console.log("🚀 ~ selectedMedia:", selectedMedia);
    const generateThumbnails = async () => {
      const newThumbnails: { [key: string]: string } = {};
      for (const media of mediaAssets) {
        try {
          if (media?.media?.content_type === "video/mp4") {
            newThumbnails[media.media.url] = await generateVideoThumbnail(
              media.media.url
            );
          } else if (media?.wistia) {
            newThumbnails[media.wistia] = await generateWistiaThumbnail(
              media?.wistia
            );
          }
        } catch (error) {
          console.error("Error generating thumbnail:", error);
        }
      }
      setThumbnails(newThumbnails);
      setSelectedMedia(mediaAssets[0]);
    };

    if (mediaAssets.length > 0) {
      generateThumbnails();
    }
  }, [mediaAssets]);

  const handleMediaClick = (media: ProductMedia) => {
    setSelectedMedia(media);
    setIsPlaying(false);
  };

  const handleVideoClick = () => {
    setIsPlaying(true);
    setIsModalOpen(true);
  };

  const isVideo = (media: ProductMedia | null) =>
    media?.media?.content_type === "video/mp4";
  const isWistia = (media: ProductMedia | null) => !!media?.wistia;

  const getModalContent = () => {
    if (!selectedMedia) return null;
    if (isVideo(selectedMedia)) {
      return (
        <video
          className={`${styles.mediaModal}`}
          controls
          autoPlay={isPlaying}
          aria-label="Selected video"
          onClick={(e) => e.stopPropagation()}
        >
          <source src={selectedMedia?.media?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (isWistia(selectedMedia)) {
      return (
        <div className={`${styles.wistiaVideoModal}`}>
          <WistiaVideo embedUrl={selectedMedia.wistia} responsive={true} />
        </div>
      );
    }

    return (
      <img
        className={`${styles.mediaModal}`}
        loading="lazy"
        alt={selectedMedia?.media?.title || "Large media"}
        src={selectedMedia?.media?.url || "large-blank-card.png"}
        onClick={handleVideoClick}
      />
    );
  };

  const getPreviewMedia = () => {
    if (!selectedMedia) return <></>;
    if (isVideo(selectedMedia)) {
      return (
        <>
          <img
            className={styles.largeVideoIcon}
            src={
              thumbnails[selectedMedia?.media.url] ?? "/large-blank-card.png"
            }
            alt={selectedMedia?.media.filename ?? selectedMedia?.media.title}
          />
          <div className={styles.playButtonWrapper}>
            <PlayBtnSVG />
          </div>
        </>
      );
    }

    if (selectedMedia.wistia) {
      return (
        <>
          <img
            className={`${styles.largeVideoIcon}`}
            src={thumbnails[selectedMedia?.wistia] ?? "/large-blank-card.png"}
            alt="Wistia Preview"
          />
          <div className={styles.playButtonWrapper}>
            <PlayBtnSVG />
          </div>
        </>
      );
    }

    return (
      <img
        className={`${styles.largeVideoIcon}`}
        loading="lazy"
        alt={selectedMedia?.media?.title || "Large media"}
        src={selectedMedia?.media?.url || "large-blank-card.png"}
        onClick={handleVideoClick}
      />
    );
  };

  const getSmallCardImageSrc = (media: ProductMedia) => {
    if (isVideo(media)) {
      return thumbnails[media.media?.url] || "placeholder-image.png";
    }
    if (isWistia(media)) {
      return thumbnails[media.wistia] || "placeholder-image.png";
    }
    return media.media?.url || "placeholder-image.png"; // Fallback if media.url is undefined
  };

  return (
    <div className={styles.productDisplayHero}>
      <section className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.productFamily}>
            {product_display_banner.title}
          </h2>
          <div className={styles.divider} />
          <div className={styles.description}>
                      {parse(DOMPurify.sanitize(product_display_banner.description))}
          </div>
          <div className={styles.buttonGroup}>
            {product_display_banner.cta?.href &&
              product_display_banner.cta?.title && (
                <Button
                  key="ctaButton"
                  text={product_display_banner.cta.title}
                  showIcon={false}
                  propWidth="250px"
                  propBackgroundColor="var(--primary-500)"
                  propHoverBackgroundColor="transparent"
                  propTextColor="var(--white)"
                  propAlignSelf="stretch"
                  link={product_display_banner.cta.href}
                />
              )}
            {product_display_banner.download?.href &&
              product_display_banner.download?.title && (
                <Button
                  key="downloadButton"
                  text={product_display_banner.download.title}
                  showIcon={false}
                  propWidth="250px"
                  propTextColor="var(--primary-500)"
                  propBackgroundColor="transparent"
                  propHoverBackgroundColor="var(--primary-500)"
                  propHoverText="var(--white)"
                  propAlignSelf="stretch"
                  link={product_display_banner.download.href}
                />
              )}
          </div>
        </div>
        {mediaAssets.length > 0 && (
          <div className={styles.videoGrid}>
            {selectedMedia && (
              <div className={styles.videoWrapper} onClick={handleVideoClick}>
                {getPreviewMedia()}
                {isPlaying && isModalOpen && (
                  <Modal
                    className={styles.productMediaModal}
                    content={getModalContent() as JSX.Element}
                    closeHandler={closeModal}
                  />
                )}
              </div>
            )}
            <div
              className={`${styles.smallCards} ${
                mediaAssets?.length < 3 ? styles.leftCard : ""
              }`}
            >
              {mediaAssets.slice(0, 3).map((media, index) => (
                <div
                  key={index}
                  className={`
                    ${
                      index === 0
                        ? styles.smallVideoIconWrapper
                        : styles.blankCardIconWrapper
                    } ${
                    JSON.stringify(media) === JSON.stringify(selectedMedia) &&
                    styles.activeWrapper
                  }
                  `}
                  onClick={() => handleMediaClick(media)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleMediaClick(media);
                    }
                  }}
                >
                  {thumbnails[media?.media?.url] ||
                  media?.media?.url ||
                  thumbnails[media?.wistia] ||
                  media?.wistia ? (
                    <img
                      className={
                        index === 0
                          ? styles.smallVideoIcon
                          : styles.blankCardIcon
                      }
                      loading="lazy"
                      alt={`Thumbnail ${index}`}
                      src={getSmallCardImageSrc(media)}
                    />
                  ) : (
                    <Skeleton width={100} />
                  )}
                  {(isVideo(media) || isWistia(media)) && (
                    <div className={styles.playButtonWrapper}>
                      <PlayBtnSVG />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
