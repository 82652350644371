import React from "react";
import { PageTitleHeader as PageTitleRes } from "../typescript/components";
import styles from "../styles/PageTitle.module.css";
import { useLocation } from "react-router-dom";

export default function PageTitle({
  page_title,
}: {
  page_title: PageTitleRes;
}) {

    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
    const filteredPathnames = pathnames.slice(1); // Remove locale from the breadcrumb

    const toProperCase = (str: string) =>
        str
            .replace(/-/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");

  return (
    <>
      <div className={styles.innerWrapper}>
              {filteredPathnames && (
                  <div className={styles.breadcrumb}>
                      <span>
                          <a href="/" className={styles.breadcrumbLink}>
                              Home
                          </a>
                      </span>
                      {filteredPathnames.map((value, index) => {
                          const pageName = value.toLowerCase();
                          const specialPages = ["article", "case study", "webinar", "white paper"];
                          const isSpecialPage = specialPages.includes(pageName);
                          const to = isSpecialPage
                              ? `/resources?type=${pageName}`
                              : `/${filteredPathnames.slice(0, index + 1).join("/")}`;
                          const isLast = index === filteredPathnames.length - 1;

                          return (
                              <React.Fragment key={to}>
                                  <span>&nbsp;/&nbsp;</span>
                                  {isLast ? (
                                      <span key={to} className={styles.currentBreadcrumb} aria-current="page">
                                          {toProperCase(value)}
                                      </span>
                                  ) : (
                                      <span key={index}>
                                          <a href={to} className={styles.breadcrumbLink}>
                                              {toProperCase(value)}
                                          </a>
                                      </span>
                                  )}
                              </React.Fragment>
                          );
                      })}
                  </div>
        )}

        <div className={styles.containerHeroTitle}>
          <h1
            className={styles.title}
            style={{ fontSize: page_title?.titleFontSize }}
          >
            {page_title?.title}
          </h1>
          {page_title?.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: page_title?.description || "",
              }} // Ensure the content is HTML
            />
          )}
        </div>
      </div>
      {!page_title.hideBorder && <div className={styles.border}></div>}
    </>
  );
}
