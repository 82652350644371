import * as React from "react";
import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

const checkedCheckboxIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="3 3 13 13" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3103 6.02344C13.3609 6.05946 13.4033 6.10438 13.4352 6.15561C13.467 6.20685 13.4877 6.2634 13.4959 6.32203C13.5042 6.38066 13.4999 6.44022 13.4833 6.49731C13.4667 6.5544 13.4381 6.6079 13.3992 6.65475L8.54289 12.5044C8.50076 12.5551 8.44743 12.597 8.38646 12.6271C8.32549 12.6572 8.25829 12.6749 8.18934 12.6791C8.12039 12.6832 8.05128 12.6736 7.98663 12.651C7.92199 12.6285 7.86329 12.5933 7.81445 12.5481L5.38632 10.2982C5.29786 10.2133 5.24891 10.0997 5.25002 9.98167C5.25112 9.86369 5.3022 9.75083 5.39224 9.6674C5.48228 9.58398 5.60408 9.53665 5.73141 9.53563C5.85874 9.5346 5.98141 9.57996 6.073 9.66192L8.1102 11.5496L12.629 6.10578C12.6678 6.0589 12.7163 6.01958 12.7716 5.99006C12.8269 5.96055 12.8879 5.94142 12.9512 5.93377C13.0145 5.92612 13.0788 5.93011 13.1404 5.94549C13.202 5.96088 13.2597 5.98736 13.3103 6.02344Z" fill="white"/>
    </svg>`;
    const encodedSVG = encodeURIComponent(checkedCheckboxIcon);
    const dataURL = `url('data:image/svg+xml;utf8,${encodedSVG}')`;

const CustomSwitch = styled("span")(({ theme }) => ({
    borderRadius: 2.5,
    width: 13,
    height: 13,
    boxShadow: "none",
    backgroundColor: "var(--white)",
    border: '1px solid var(--charcoal-200)',
}));

const BpCheckedIcon = styled(CustomSwitch)({
    backgroundColor: "var(--brand-primary-500)",
    "&::before": {
        display: "block",
        width: 13,
        height: 13,
        backgroundImage: dataURL,
        content: '""',
    },
});

export default function CustomizedCheckbox(props: CheckboxProps) {
    return (
        <Checkbox
            sx={{ "&:hover": { bgcolor: "transparent" } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<CustomSwitch />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
        />
    );
}
