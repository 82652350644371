import React from "react";
import styles from "../styles/CompanyCareer.module.css";
import { CompanyCareerProps } from "../typescript/components";

export default function CompanyCareer({
  company_career,
}: {
  company_career: CompanyCareerProps;
}) {
  const { icon, title, description, company_logo } = company_career;
  return (
    <div className={styles.CompanyCareerContainer}>
      {icon.url && (
        <img
          className={styles.logoTeam}
          loading="lazy"
          alt={description || "Company Career Title"}
          src={icon.url}
        />
      )}
      <div className={styles.CompanyCareerTitle}>{title}</div>
      {description && (
        <div className={styles.CompanyCareerBody}>
          {description}
        </div>
      )}
      {company_logo?.length > 0 && (
        <div className={styles.CompanyCareerLogoGroups}>
          {company_logo.map((logo, index) => (
            <a
              key={index}
              className={[
                styles.CompanyCareerLogoGroup_Link,
                styles[`CompanyCareerLogoGroup_Link${index + 1}`],
              ].join(" ")}
              href={logo.link.href}
            >
              <div className={styles.CompanyCareerLogoGroup}>
                <img
                  loading="lazy"
                  alt={logo.logo.description || "Logo Company Career"}
                  src={logo.logo.url}
                />
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
