import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import BottomFooter from "./bottom-footer";
import DevTools from "./devtools";
import { getHeaderRes, getFooterRes, getBottomFooterRes } from "../helper";
import { onEntryChange } from "../sdk/entry";
import { EntryProps } from "../typescript/components";
import {
  BottomFooterRes,
  FooterRes,
  HeaderRes,
  NavigationMenu,
  PageReference,
} from "../typescript/response";
import NotificationBar from "./notification-bar";
import { useTranslation } from "react-i18next";
import { cachedLocales } from "../sdk/i18n";

export default function Layout({ entry }: { entry: EntryProps }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [cache, setCache] = useState<{ [language: string]: any }>({});
  const [layout, setLayout] = useState({
    header: {} as HeaderRes,
    footer: {} as FooterRes,
    bottomFooter: {} as BottomFooterRes,
    navHeaderList: [] as NavigationMenu[],
    navFooterList: [] as PageReference[],
    entry,
  });
  const [error, setError] = useState(false);

  const isErrorWithCode = (error: unknown): error is { error_code: number } =>
    typeof error === "object" && error !== null && "error_code" in error;

  const fetchData = useCallback(async () => {
    const currentLanguage = i18n.language;

    if (cache[currentLanguage]) {
      setLayout(cache[currentLanguage]);
      return;
    }

    try {
      const [header, footer, bottomFooter] = await Promise.all([
        getHeaderRes(),
        getFooterRes(),
        getBottomFooterRes(),
      ]);

      if (!header || !footer || !bottomFooter) {
        throw new Error("Missing layout data");
      }

      const navHeaderList = [...header.navigation_menu];
      const navFooterList = [...footer.navigation_menu];
      const languages = cachedLocales;

      header.currentLocale = currentLanguage;
      header.languages = languages;

      const newLayout = {
        header,
        footer,
        bottomFooter,
        navHeaderList,
        navFooterList,
        entry,
      };

      setCache((prevCache) => ({
        ...prevCache,
        [currentLanguage]: newLayout,
      }));

      setLayout(newLayout);
    } catch (error: unknown) {
      if (isErrorWithCode(error) && error?.error_code === 141) {
        const path = location.pathname.split("/").shift();
        const newPath = `/${path}${location.search ?? ""}`;
        navigate(newPath, { replace: true });
      } else {
        setError(true);
        console.error("Error fetching data:", error);
      }
    }
  }, [i18n.language, location, navigate, entry, cache]);

  useEffect(() => {
    fetchData();
    onEntryChange(fetchData);
  }, [fetchData]);

  useEffect(() => {
    if (error) {
      console.error("Error loading layout data");
      navigate("/error");
    }
  }, [error, navigate]);

  const jsonObj = useMemo(
    () => ({
      header: layout.header,
      footer: layout.footer,
      bottomFooter: layout.bottomFooter,
      entry: layout.entry,
    }),
    [layout]
  );

  return (
    <div className="layout">
      <Header header={layout.header} navMenu={layout.navHeaderList} />
      <NotificationBar notification_bar={layout.header.notification_bar} />
      <DevTools response={jsonObj} />
      <Outlet />
      <Footer footer={layout.footer} navMenu={layout.navFooterList} />
      <BottomFooter bottomFooter={layout.bottomFooter} />
    </div>
  );
}