// wistia-video.tsx
import React, { useEffect, useRef } from "react";
import { extractWistiaIdFromHtmlSnippet } from "../sdk/utils";
import { handleWistiaInstance, removeWistiaInstance } from "../helper/wistiaUtils";
import styles from "../styles/WistiaVideo.module.css";

interface WistiaVideoProps {
  embedUrl: string;
  width?: string;
  height?: string;
  padding?: string;
  responsive?: boolean;
}

export default function WistiaVideo({
  embedUrl,
  width = undefined,
  height = undefined,
  responsive = true,
  padding = "56.25% 0 0",
}: WistiaVideoProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoId = extractWistiaIdFromHtmlSnippet(embedUrl);

  useEffect(() => {
    if (!videoId) return;

    handleWistiaInstance(embedUrl);

    return () => {
      removeWistiaInstance(videoId); // Clean up instance
    };
  }, [embedUrl, videoId]);

  if (!responsive) {
    return (
      <div
        ref={containerRef}
        className={`wistia_embed wistia_async_${videoId}`}
        style={{
          height: "366px",
          width: "600px",
        }}
      >
        &nbsp;
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={`wistia_responsive_padding ${styles.videoWrapper}`}
      style={{
        padding: padding,
        position: "relative",
        width: width,
        height: height,
      }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          position: "absolute",
          height: "100%",
          left: 0,
          top: 0,
          width: "100%",
        }}
      >
        <div
          className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
          style={{
            height: "100%",
            position: "relative",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}