import React from "react";
import styles from "../styles/LogoBanner.module.css";
import Skeleton from "react-loading-skeleton";
import { LogoBanner as LogoBannerProp } from "../typescript/components";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default function LogoBanner({
  logoBanner,
}: {
  logoBanner: LogoBannerProp;
}) {

  if (!logoBanner.logos || logoBanner.logos.length === 0) {
    return null;
  }

  const appendHeightParam = (url: string, height: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", height.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };

  const slidesPerView = logoBanner.display_options.slides_per_view ?? 4;
  return (
    <div className={styles.container}>
      <Swiper
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={80}
        max-width={100}
        speed={1200}
        slidesPerView={slidesPerView ?? 4}
        slidesPerGroup={logoBanner.display_options.slides_per_group ?? 1}
        loop={true}
        autoplay={{
          delay: logoBanner.display_options.autoplay_delay ?? 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          360: {
            slidesPerView: 3,
            spaceBetween: 5,
            height: 43,
          },
          380: {
            slidesPerView: 4,
            spaceBetween: 5,
            height: 43,
          },
          460: {
            slidesPerView: 4,
            spaceBetween: 10,
            height: 43,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 60,
          },
          1400: {
            slidesPerView: 8,
            spaceBetween: 80,
          },
        }}
      >
        {logoBanner.logos.length > 0
          ? logoBanner.logos.map((item, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                {item.logo ? (
                  <img
                    src={appendHeightParam(item.logo.url, 125)}
                    alt={item.logo.title || "Logo image"}
                    className={styles.logoImage}
                  />
                ) : (
                  <p className={styles.logo}>{item.description}</p>
                )}
              </SwiperSlide>
            ))
          : Array.from({ length: 4 }).map((_, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                <Skeleton count={1} className={styles.logo} />
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
}
